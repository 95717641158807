export const usersPage = {
  create: {
    createButton: 'Add team member',
    modalHeader: 'Member details',
    modalDescription: 'Add a team member to your organisation',
    fields: {
      firstName: 'First name',
      lastName: 'Family name',
      email: 'Email'
    }
  },
  edit: {
    fetching: 'Loading User details',
    orgModal: {
      button: 'View organisation',
      desc: 'Show organisation details',
      header: 'Organisation details'
    }
  },
  list: {
    createNewButton: 'Add a team member',
    emptyList: 'There are no team members for current organisation',
    fetching: 'Loading team members',
    tableName: 'List of tem members',
    userLink: 'View user'
  }
} as const
