export const modelsPage = {
  create: {
    button: 'Create Model',
    emptyImageOption: 'Select image...',
    legend: 'Create model',
    fields: {
      name: {
        label: 'Model name'
      }
    }
  },
  item: {
    actionHeader: 'Actions',
    link: 'Versions',
    loading: 'Loading Models',
    editLink: 'Edit model',
    version: {
      creator: 'Creator',
      dockerImage: 'Docker image',
      name: 'Name',
      org: 'Organisation',
      status: 'Status',
      version: 'Version'
    }
  },
  list: {
    emptyMessage: 'No models',
    errorMessage: 'Problem loading Models',
    loadingMessage: 'Loading models',
    tableName: 'List of models',
    viewLink: 'View Model'
  }
} as const
