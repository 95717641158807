import {
  AirbyteActions,
  AirbyteState,
  SourceDefinitionItem
} from '../@types/airbyte'

export const AirbyteInitialState = {
  fetching: false,
  items: [],
  error: null,
  extendData: null,
  unsupportedItems: null
}

export const AirbyteReducer = (
  state: AirbyteState,
  action: AirbyteActions
): AirbyteState => {
  switch (action.type) {
    case 'AIRBYTE_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      }
    case 'AIRBYTE_ERROR':
      return {
        ...state,
        fetching: false,
        error: action.error || null
      }

    case 'AIRBYTE_SUCCESS':
      return {
        ...state,
        fetching: false,
        error: null,
        items: action.items as SourceDefinitionItem[]
      }
    case 'AIRBYTE_UNSUPPORTED_REQUEST':
      return {
        ...state,
        unsupportedItems: {
          fetching: true,
          error: null,
          completed: false
        }
      }
    case 'AIRBYTE_UNSUPPORTED_ERROR':
      return {
        ...state,
        unsupportedItems: {
          fetching: false,
          error: action.error || null
        }
      }

    case 'AIRBYTE_UNSUPPORTED_SUCCESS':
      return {
        ...state,
        unsupportedItems: {
          fetching: false,
          completed: true,
          items: action.items as SourceDefinitionItem[]
        }
      }
    case 'AIRBYTE_UNSUPPORTED_CLEAR':
      return {
        ...state,
        unsupportedItems: null
      }
    case 'AIRBYTE_EXTEND_SET': {
      return {
        ...state,
        extendData: {
          id: action.id
        }
      }
    }
    case 'AIRBYTE_EXTEND_REQUEST':
      return {
        ...state,
        extendData: {
          ...state.extendData,
          fetching: true,
          error: null,
          completed: false
        }
      }
    case 'AIRBYTE_EXTEND_SUCCESS':
      return {
        ...state,
        items: state.items.map((item) =>
          item.sourceDefinitionId === action.definition?.sourceDefinitionId
            ? { ...item, definition: action.definition }
            : item
        ),
        extendData: {
          ...state.extendData,
          fetching: false,
          completed: true
        }
      }
    case 'AIRBYTE_EXTEND_ERROR':
      return {
        ...state,
        extendData: {
          ...state.extendData,
          fetching: false,
          error: action.error || null
        }
      }
    case 'AIRBYTE_EXTEND_CLEAR':
      return {
        ...state,
        extendData: null
      }
    default:
      return state
  }
}
