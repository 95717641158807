import { User, UsersActions, UsersState } from '../@types/users'

export const UsersInitialState = {
  items: [],
  fetching: false,
  addData: null,
  params: null,
  error: null
}

export const UsersReducer = (
  state: UsersState,
  action: UsersActions
): UsersState => {
  switch (action.type) {
    // case `ADD_SEARCH_PARAMS`:
    //     return {
    //         ...state,
    //         params: action.params
    //     }
    // case `CLEAR_SEARCH_PARAMS`:
    //     return {
    //         ...state,
    //         params: null
    //     }
    case 'USERS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      }
    case 'USERS_WITH_PARAMS_REQUEST':
      return {
        ...state,
        params: action.params
      }
    case 'USERS_SUCCESS':
      return {
        ...state,
        items: action.items || [],
        fetching: false,
        params: null
      }
    case 'USERS_ERROR':
      return {
        ...state,
        fetching: false,
        error: action.error || null,
        params: null
      }
    case 'USER_ADD_REQUEST':
      return {
        ...state,
        addData: {
          fetching: true,
          completed: false,
          error: null,
          data: action.addData?.data
        }
      }
    case 'USER_ADD_SUCCESS':
      return {
        ...state,
        items: [...state.items, ...[action.user as User]],
        addData: {
          ...state.addData,
          completed: true,
          fetching: false,
          error: null
        }
      }
    case 'USER_ADD_ERROR':
      return {
        ...state,
        addData: {
          ...state.addData,
          fetching: false,
          error: action.error
        }
      }
    case 'USER_ADD_CLEAR':
      return {
        ...state,
        addData: null
      }
    default:
      return state
  }
}
