export const dataElementsPage = {
  create: {
    button: 'Create Data Element',
    pickerSummary: (num : number) => `${num} Data Element${num === 1 ? '' : 's'} selected`
  },
  item: {
    dataElement: {
      creator: 'Creator',
      name: 'Name',
      org: 'Organisation'
    },
    externalStatus: 'External status',
    loading: 'Loading data element',
    status: 'Status',
    version: 'Version',
    viewLink: 'Versions'
  },
  list: {
    header: 'Data elements',
    tableName: 'Data elements'
  }
} as const
