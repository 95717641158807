import React from 'react'

interface NavProps {
    children: React.ReactNode,
    className?: string
}

const Nav = ({ children, className } : NavProps) : JSX.Element => (
    <nav className={className}>
        {children}
    </nav>
)

export default Nav
