import { useKeycloak } from '@react-keycloak/web'
import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { featureFlags } from '../config/flags'
import { navigationInfo } from '../config/urls'
import AirbyteStore from '../context/AirbyteStore'
import DataElementDesignerStore from '../context/DataElementDesignerStore'
import DataElementsStore from '../context/DataElementsStore'
import DataExplorerStore from '../context/DataExplorerStore'
import DataSourcesStore from '../context/DataSourcesStore'
import NotificationsStore from '../context/NotificationsStore'
import ProjectsStore from '../context/ProjectsStore'
import UsersStore from '../context/UsersStore'
import { en } from '../locales/en'
import PrivateRoute from '../routes/PrivateRoute'

import Div from './elements/Div'
import ErrorBoundary from './ErrorBoundary'
import Navigation from './layout/Navigation'
import Loading from './Loading'
import Notifications from './Notifications'

const BetaTestingTrialLicenceOrderPage = React.lazy(
  () => import('../pages/BetaTestingTrialLicenceOrder')
)
const DashboardPage = React.lazy(() => import('../pages/Dashboard'))
const DataElementsCreatePage = React.lazy(
  () => import('../pages/DataElementsCreatePage')
)
const DataElementsPage = React.lazy(() => import('../pages/DataElementsPage'))
const DataExplorerConfigurePage = React.lazy(
  () => import('../pages/data-explorer/Configure')
)
const DataExplorerConfirmPage = React.lazy(
  () => import('../pages/data-explorer/Confirm')
)
const DataExplorerEditPage = React.lazy(
  () => import('../pages/data-explorer/Edit')
)
const DataExplorerLandingPage = React.lazy(
  () => import('../pages/data-explorer/Landing')
)
const DataExplorerPendingPage = React.lazy(
  () => import('../pages/data-explorer/Pending')
)
const DataExplorerSourcesPage = React.lazy(
  () => import('../pages/data-explorer/Sources')
)
const DataProcessingAgreementPage = React.lazy(
  () => import('../pages/DataProcessingAgreement')
)
const DataSourceCreatePage = React.lazy(
  () => import('../pages/data-sources/DataSourceCreatePage')
)
const DataSourcePage = React.lazy(
  () => import('../pages/data-sources/DataSourcePage')
)
const HomePage = React.lazy(() => import('../pages/HomePage'))
const LoginPage = React.lazy(() => import('../pages/LoginPage'))
const MasterLicenceAndServicesAgreementPage = React.lazy(
  () => import('../pages/MasterLicenceAndServicesAgreement')
)
const ModelPage = React.lazy(() => import('../pages/ModelPage'))
const ModelsPage = React.lazy(() => import('../pages/ModelsPage'))
const NoMatchPage = React.lazy(() => import('../pages/NoMatchPage'))
const ProjectDataElementsPage = React.lazy(
  () => import('../pages/ProjectDataElementsPage')
)
const ProjectDataSourcesPage = React.lazy(
  () => import('../pages/data-sources/ProjectDataSourcesPage')
)
const ProjectModelCreatePage = React.lazy(
  () => import('../pages/ProjectModelCreatePage')
)
const ProjectModelEditPage = React.lazy(
  () => import('../pages/ProjectModelEditPage')
)
const ProjectModelPage = React.lazy(() => import('../pages/ProjectModelPage'))
const ProjectModelsPage = React.lazy(() => import('../pages/ProjectModelsPage'))
const ProjectPage = React.lazy(() => import('../pages/ProjectPage'))
const ProjectsPage = React.lazy(() => import('../pages/ProjectsPage'))
const ResourcesPage = React.lazy(() => import('../pages/ResourcesPage'))
const ScoringPage = React.lazy(() => import('../pages/ScoringPage'))
const SettingsPage = React.lazy(() => import('../pages/Settings'))
const TrainingPage = React.lazy(() => import('../pages/TrainingPage'))
const UserPage = React.lazy(() => import('../pages/UserPage'))
const UsersPage = React.lazy(() => import('../pages/UsersPage'))

const App = (): JSX.Element => {
  const {
    betaTestingTrialLicenceOrder,
    dashboard,
    dataElements,
    dataElementsCreate,
    dataExplorerConfigure,
    dataExplorerConfirm,
    dataExplorerEdit,
    dataExplorerLanding,
    dataExplorerPending,
    dataExplorerSources,
    dataProcessingAgreement,
    dataSource,
    dataSourceCreate,
    home,
    login,
    masterLicenceAndServicesAgreement,
    model,
    models,
    project,
    projectDataElements,
    projectDataSources,
    projectModel,
    projectModelCreate,
    projectModelEdit,
    projectModels,
    projects,
    resources,
    scoring,
    settings,
    training,
    user,
    users
  } = navigationInfo

  const { keycloak, initialized } = useKeycloak()

  if (!initialized) {
    return (
      <div className={'box'}>
        <Loading message={en.components.loading.globalLoading} />
      </div>
    )
  }

  return (
    <ErrorBoundary>
      <Router>
        <Div className={'app-grid'}>
          {keycloak?.authenticated && (
            <Div>
              <ProjectsStore>
                <Navigation />
              </ProjectsStore>
            </Div>
          )}
          <Suspense fallback={<Loading message={en.api.messages.suspense} />}>
            <Div className='dashboard-container'>
              <Switch>
                <Route
                  path={dataProcessingAgreement.url}
                  exact={true}
                  component={DataProcessingAgreementPage}
                />
                <Route
                  path={masterLicenceAndServicesAgreement.url}
                  exact={true}
                  component={MasterLicenceAndServicesAgreementPage}
                />
                <Route
                  path={betaTestingTrialLicenceOrder.url}
                  exact={true}
                  component={BetaTestingTrialLicenceOrderPage}
                />
                <DataSourcesStore>
                  <ProjectsStore>
                    <Route
                      path={home.url}
                      exact={true}
                      component={HomePage}
                    />
                    <PrivateRoute
                      path={dashboard.url}
                      exact={true}
                      component={
                        featureFlags.DASHBOARD ? DashboardPage : NoMatchPage
                      }
                    />
                    <DataExplorerStore>
                      <AirbyteStore>
                        <PrivateRoute
                          path={dataExplorerLanding.url}
                          exact={true}
                          component={
                            featureFlags.DATA_SOURCES_LANDING_MVP
                              ? DataExplorerLandingPage
                              : NoMatchPage
                          }
                        />
                        <PrivateRoute
                          path={dataExplorerSources.url}
                          exact={true}
                          component={DataExplorerSourcesPage}
                        />
                        <PrivateRoute
                          path={dataExplorerConfirm.url}
                          exact={true}
                          component={DataExplorerConfirmPage}
                        />
                        <PrivateRoute
                          path={dataExplorerConfigure.url}
                          exact={true}
                          component={DataExplorerConfigurePage}
                        />
                        <PrivateRoute
                          path={dataExplorerPending.url}
                          exact={true}
                          component={DataExplorerPendingPage}
                        />
                        <PrivateRoute
                          path={dataExplorerEdit.url}
                          exact={true}
                          component={
                            featureFlags.DATA_SOURCE_EDIT
                              ? DataExplorerEditPage
                              : NoMatchPage
                          }
                        />
                      </AirbyteStore>
                    </DataExplorerStore>
                    <DataElementsStore>
                      <PrivateRoute
                        path={projectDataElements.url}
                        exact={true}
                        component={ProjectDataElementsPage}
                      />
                      <PrivateRoute
                        path={dataElements.url}
                        exact={true}
                        component={
                          featureFlags.DATA_ELEMENTS
                            ? DataElementsPage
                            : NoMatchPage
                        }
                      />
                      <DataElementDesignerStore>
                        <PrivateRoute
                          path={dataElementsCreate.url}
                          exact={true}
                          component={DataElementsCreatePage}
                        />
                      </DataElementDesignerStore>
                      <PrivateRoute
                        path={projectModelCreate.url}
                        exact={true}
                        component={ProjectModelCreatePage}
                      />
                      <PrivateRoute
                        path={project.url}
                        exact={true}
                        component={ProjectPage}
                      />
                    </DataElementsStore>
                    <PrivateRoute
                      path={projects.url}
                      exact={true}
                      component={
                        featureFlags.PROJECTS ? ProjectsPage : NoMatchPage
                      }
                    />
                    <PrivateRoute
                      path={projectDataSources.url}
                      exact={true}
                      component={ProjectDataSourcesPage}
                    />
                    <PrivateRoute
                      path={projectModels.url}
                      exact={true}
                      component={ProjectModelsPage}
                    />
                    <PrivateRoute
                      path={projectModel.url}
                      exact={true}
                      component={ProjectModelPage}
                    />
                    <PrivateRoute
                      path={projectModelEdit.url}
                      exact={true}
                      component={ProjectModelEditPage}
                    />
                    <PrivateRoute
                      path={models.url}
                      exact={true}
                      component={featureFlags.MODELS ? ModelsPage : NoMatchPage}
                    />
                    <PrivateRoute
                      path={model.url}
                      exact={true}
                      component={featureFlags.MODEL ? ModelPage : NoMatchPage}
                    />
                    <PrivateRoute
                      path={dataSource.url}
                      exact={true}
                      component={
                        featureFlags.DATA_SOURCES ? DataSourcePage : NoMatchPage
                      }
                    />
                    <PrivateRoute
                      path={dataSourceCreate.url}
                      exact={true}
                      component={DataSourceCreatePage}
                    />
                    <PrivateRoute
                      path={training.url}
                      exact={true}
                      component={
                        featureFlags.TRAINING ? TrainingPage : NoMatchPage
                      }
                    />
                    <PrivateRoute
                      path={scoring.url}
                      exact={true}
                      component={
                        featureFlags.SCORING ? ScoringPage : NoMatchPage
                      }
                    />
                    <PrivateRoute
                      path={resources.url}
                      exact={true}
                      component={
                        featureFlags.RESOURCES ? ResourcesPage : NoMatchPage
                      }
                    />
                    <PrivateRoute
                      path={settings.url}
                      exact={true}
                      component={
                        featureFlags.SETTINGS ? SettingsPage : NoMatchPage
                      }
                    />
                    <UsersStore>
                      <PrivateRoute
                        path={user.url}
                        exact={true}
                        component={
                          featureFlags.SETTINGS ? UserPage : NoMatchPage
                        }
                      />
                      <PrivateRoute
                        path={users.url}
                        exact={true}
                        component={
                          featureFlags.SETTINGS ? UsersPage : NoMatchPage
                        }
                      />
                    </UsersStore>
                    <Route
                      path={login.url}
                      exact={true}
                      component={LoginPage}
                    />
                  </ProjectsStore>
                </DataSourcesStore>
              </Switch>
            </Div>
          </Suspense>
        </Div>
        {featureFlags.NOTIFICATIONS && keycloak?.authenticated && (
          <NotificationsStore>
            <Notifications stacked={true} />
          </NotificationsStore>
        )}
      </Router>
    </ErrorBoundary>
  )
}

export default App
