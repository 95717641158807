import React, { createContext, useEffect, useMemo, useReducer } from 'react'

import {
  AirbyteActions,
  AirbyteState,
  SourceDefinitionItem
} from '../@types/airbyte'
import { AirbyteSupportedNames } from '../components/app/data-explorer/util/dataExplorer'
import { useAxios } from '../hooks/useAxios'
import { AirbyteInitialState, AirbyteReducer } from '../reducers/AirbyteReducer'

interface AirbyteStoreProps {
  children: React.ReactNode
}

interface ContextProps {
  stateAirbyte: AirbyteState
  dispatchAirbyte: React.Dispatch<AirbyteActions>
}

const AirbyteStore = ({ children }: AirbyteStoreProps): JSX.Element => {
  const [state, dispatch] = useReducer(AirbyteReducer, AirbyteInitialState)

  const axiosInstance = useAxios()

  const filters = useMemo(
    () => [
      AirbyteSupportedNames.mailchimp,
      AirbyteSupportedNames.shopify,
      AirbyteSupportedNames.surveyMonkey
    ],
    []
  )

  useEffect(() => {
    if (state.items.length === 0) {
      dispatch({ type: 'AIRBYTE_REQUEST' })
      axiosInstance.current
        ?.get(`/api/v1/airbyte/sourceDefinitions?filter=${filters.join(',')}`)
        .then((res) => {
          // dispatch({type: `AIRBYTE_SUCCESS`, items: res.data.sourceDefinitions})
          /* TMP sorting in the UI */
          dispatch({
            type: 'AIRBYTE_SUCCESS',
            items: res.data.sourceDefinitions.sort(
              (a: { name: string }, b: { name: string }) => a.name > b.name
            )
          })
        })
        .catch((error) => {
          dispatch({ type: 'AIRBYTE_ERROR', error })
        })
    }
  }, [axiosInstance, state.items, filters])

  useEffect(() => {
    if (
      state.unsupportedItems &&
      !state.unsupportedItems.error &&
      !state.unsupportedItems.completed
    ) {
      axiosInstance.current
        ?.get('/api/v1/airbyte/sourceDefinitions')
        .then((res) => {
          dispatch({
            type: 'AIRBYTE_UNSUPPORTED_SUCCESS',
            items: res.data.sourceDefinitions
              .filter(
                ({ name }: SourceDefinitionItem) => filters.indexOf(name) === -1
              )
              /* TMP sorting in the UI */
              .sort(
                (a: { name: string }, b: { name: string }) => a.name > b.name
              )
          })
        })
        .catch((error) => {
          dispatch({ type: 'AIRBYTE_UNSUPPORTED_ERROR', error })
        })
    }
  }, [axiosInstance, state.unsupportedItems, filters])

  useEffect(() => {
    if (state.extendData?.completed) {
      dispatch({ type: 'AIRBYTE_EXTEND_CLEAR' })
    }
  }, [state.extendData?.completed])

  useEffect(() => {
    if (
      state.extendData &&
      !state.extendData.fetching &&
      !state.extendData.error &&
      !state.extendData.completed
    ) {
      dispatch({ type: 'AIRBYTE_EXTEND_REQUEST' })
      axiosInstance.current
        ?.get(`/api/v1/airbyte/sourceDefinitions/${state.extendData.id}`)
        .then((res) => {
          dispatch({ type: 'AIRBYTE_EXTEND_SUCCESS', definition: res.data })
        })
        .catch((error) => {
          dispatch({ type: 'AIRBYTE_EXTEND_ERROR', error })
        })
    }
  }, [axiosInstance, state.extendData])

  return (
    <AirbyteStoreContext.Provider
      value={{ stateAirbyte: state, dispatchAirbyte: dispatch }}>
      {children}
    </AirbyteStoreContext.Provider>
  )
}

export const AirbyteStoreContext = createContext<ContextProps>(
  {} as ContextProps
)

export default AirbyteStore
