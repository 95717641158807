import { dataElementDesigner } from './components/dataElementDesigner'
import { dataElementPicker } from './components/dataElementPicker'
import { dataGrid } from './components/dataGrid'
import { errorBoundary } from './components/errorBoundary'
import { formFields } from './components/formFields'
import { layoutToggle } from './components/layoutToggle'
import { loading } from './components/loading'
import { modal } from './components/modal'
import { navigationInfo } from './components/navigationInfo'
import { pagination } from './components/pagination'
import { sessionModal } from './components/sessionModal'
import { errors } from './config/errors'
import { a } from './elements/a'
import { input } from './elements/input'
import { dashboardPage } from './pages/dashboardPage'
import { dataElementsPage } from './pages/dataElementsPage'
import { dataExplorerEditPage } from './pages/dataExplorerEditPage'
import { dataExplorerPage } from './pages/dataExplorerPage'
import { dataSourcesPage } from './pages/dataSourcesPage'
import { homePage } from './pages/homePage'
import { loginPage } from './pages/loginPage'
import { logout } from './pages/logout'
import { modelsPage } from './pages/modelsPage'
import { noMatch } from './pages/noMatch'
import { projectPage } from './pages/projectPage'
import { projectsPage } from './pages/projectsPage'
import { usersPage } from './pages/usersPage'

export const en = {
  api: {
    messages: {
      empty: '0 items',
      fetching: 'Loading...',
      suspense: 'Loading application'
    }
  },
  components: {
    airbyteIcon: {
      noImage: 'No image'
    },
    dataGrid,
    dataElementDesigner,
    dataElementPicker,
    errorBoundary,
    formFields,
    layoutToggle,
    loading,
    modal,
    pagination,
    sessionModal
  },
  elements: {
    a,
    input
  },
  errors: errors,
  navigationInfo,
  pages: {
    dashboardPage,
    dataElementsPage,
    dataExplorerPage,
    dataExplorerEditPage,
    dataSourcesPage,
    homePage,
    loginPage,
    logout,
    modelsPage,
    noMatch,
    projectPage,
    projectsPage,
    usersPage
  }
} as const
