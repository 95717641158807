import React from 'react'

interface VisuallyHiddenProps {
  children: React.ReactNode
}

const VisuallyHidden = ({ children }: VisuallyHiddenProps): JSX.Element => (
  <span className={'visually-hidden'}>{children}</span>
)

export default VisuallyHidden
