import React, { createContext, useReducer } from 'react'

import {
  DataElementDesignerActions,
  DataElementDesignerInitialState,
  DataElementDesignerReducer,
  DataElementDesignerState
} from '../reducers/DataElementDesignerReducer'

interface DataElementDesignerStoreProps {
  children: React.ReactNode
}

interface ContextProps {
  state: DataElementDesignerState
  dispatch: React.Dispatch<DataElementDesignerActions>
}

const DataElementDesignerStore = ({
  children
}: DataElementDesignerStoreProps): JSX.Element => {
  const [state, dispatch] = useReducer(
    DataElementDesignerReducer,
    DataElementDesignerInitialState
  )

  return (
    <DataElementDesignerStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </DataElementDesignerStoreContext.Provider>
  )
}

export const DataElementDesignerStoreContext = createContext<ContextProps>(
  {} as ContextProps
)

export default DataElementDesignerStore
