export const formFields = {
  booleanLabels: {
    true: 'Yes',
    false: 'No'
  },
  optional: '- optional',
  messages: {
    pattern: 'Invalid format',
    required: 'Required field'
  },
  multi: {
    required: ' (required field)'
  }
} as const
