export const projectsPage = {
  create: {
    labelDesc: 'Description',
    labelName: 'Project name',
    startButton: 'Create project',
    submitButton: 'Add',
    modalHeader: 'New project',
    modalDesc: 'Create a new project for you organisation'
  },
  list: {
    deleteLink: 'Delete project',
    editLink: 'Edit project',
    tableName: 'List of projects',
    viewLink: 'View project'
  }
} as const
