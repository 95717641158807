export const dataSourcesPage = {
  create: {
    button: 'Create Data Source',
    clear: 'Clear',
    legend: 'Data Source details',
    airByteLegend: 'Source details',
    fields: {
      connector: {
        emptyOption: 'Select type...',
        fetching: 'Loading connection definitions',
        label: 'Connection type'
      },
      file: {
        label: 'Upload file'
      },
      name: {
        label: 'Name',
        describe: 'To uniquely identify your data source'
      },
      notes: {
        label: 'Notes'
      }
    },
    tabs: {
      direct: 'Direct',
      connect: 'Airbyte'
    }
  },
  item: {
    actionHeader: 'Actions',
    buttonDelete: 'Remove source',
    deleteModal: {
      buttonCancel: 'Cancel',
      buttonDelete: 'Delete',
      deletePrefix: 'Delete',
      desc: 'You are about to delete this source, are you sure?',
      header: 'Delete source'
    },
    hiddenDelete: 'Delete',
    hiddenEdit: 'Edit',
    hiddenEditPrefix: 'Edit',
    hiddenTitlePrefix: 'Delete',
    loading: 'Loading data source',
    version: {
      dataVersion: 'Data version',
      schemaVersion: 'Schema version',
      status: 'Status',
      externalRefStatus: 'External status',
      schema: 'Schema',
      notes: 'Notes'
    }
  },
  list: {
    empty: 'no imported data sources yet',
    header: 'Data sources',
    loading: 'Fetching data sources',
    refresh: 'Reload',
    tableName: 'Data sources',
    viewLink: 'Versions'
  }
} as const
