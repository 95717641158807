import React, { createContext, useContext, useEffect, useReducer } from 'react'

import { Project, ProjectsActions, ProjectsState } from '../@types/projects'
import { useAxios } from '../hooks/useAxios'
import {
  ProjectsInitialState,
  ProjectsReducer
} from '../reducers/ProjectsReducer'

import { DataSourcesStoreContext } from './DataSourcesStore'

interface ProjectsStoreProps {
  children: React.ReactNode
}

interface ContextProps {
  state: ProjectsState
  dispatch: React.Dispatch<ProjectsActions>
}

const ProjectsStore = ({ children }: ProjectsStoreProps): JSX.Element => {
  const axiosInstance = useAxios()

  const [state, dispatch] = useReducer(ProjectsReducer, ProjectsInitialState)
  const { dispatchDataSource } = useContext(DataSourcesStoreContext)

  useEffect(() => {
    dispatch({ type: 'PROJECTS_REQUEST' })
    axiosInstance.current
      ?.get('/api/v1/projects')
      .then((res) => {
        dispatch({ type: 'PROJECTS_SUCCESS', items: res.data as Project[] })
        dispatchDataSource({
          type: 'DATA_SOURCES_REQUEST_TRIGGER',
          trigger: res.data.find((item: Project) => item.default).id
        })
      })
      .catch((error) => {
        dispatch({ type: 'PROJECTS_ERROR', error: error })
      })
  }, [axiosInstance, dispatchDataSource])

  useEffect(() => {
    if (state.addData && !state.addData.error && !state.addData.completed) {
      axiosInstance.current
        ?.post('/api/v1/projects', state.addData.data)
        .then((res) => {
          dispatch({
            type: 'PROJECTS_ADD_SUCCESS',
            project: res.data as Project
          })
        })
        .catch((error) => {
          dispatch({ type: 'PROJECTS_ADD_ERROR', error })
        })
    }
  }, [axiosInstance, state.addData])

  return (
    <ProjectsStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </ProjectsStoreContext.Provider>
  )
}

export const ProjectsStoreContext = createContext<ContextProps>(
  {} as ContextProps
)

export default ProjectsStore
