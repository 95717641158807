export const dataExplorerEditPage = {
  cancelButton: 'Cancel',
  cancelModal: 'Cancel',
  confirmModal: 'Edit',
  descModal: 'Confirm that you really really want to replace data',
  error: 'Error fetching extra data',
  fetching: 'Fetching data source',
  headerModal: 'Edit source',
  updateButton: 'Update Data source',
  warning: 'You are about to edit this source, this means the info of your dashboard will change. Are you sure?'
} as const
