import { DataExplorerActions, DataExplorerState } from '../@types/dataExplorer'

export const DataExplorerInitialState = {
  providers: [],
  configurations: [],
  configurationProgress: 0
}

export const DataExplorerReducer = (
  state: DataExplorerState,
  action: DataExplorerActions
): DataExplorerState => {
  switch (action.type) {
    case 'SET_PROVIDERS':
      return {
        ...state,
        providers: action.providers as string[]
      }
    case 'SET_CONFIGURATION':
      return {
        ...state,
        configurations: action.configurations || []
      }
    case 'INCREMENT_CONFIGURATION_PROGRESS':
      return {
        ...state,
        configurationProgress: state.configurationProgress + 1
      }
    case 'RESET':
      return {
        ...state,
        providers: [],
        configurationProgress: 0
      }
    default:
      return state
  }
}
