import {
  Notification,
  NotificationsActions,
  NotificationsState
} from '../@types/notifications'

export const NotificationsInitialState = {
  notifications: []
}

export const NotificationsReducer = (
  state: NotificationsState,
  action: NotificationsActions
): NotificationsState => {
  switch (action.type) {
    case 'NOTIFICATION_ADD':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          ...[action.notification as Notification]
        ]
      }
    case 'NOTIFICATION_REMOVE':
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.notificationId
        )
      }
    default:
      return state
  }
}
