import React from 'react'

import { en } from '../locales/en'

import Div from './elements/Div'
import Small from './elements/Small'
import ErrorBoundary from './ErrorBoundary'

interface LoadingProps {
  message: string
}

const Loading = ({
  message = en.components.loading.defaultMessage
}: LoadingProps): JSX.Element => (
  <ErrorBoundary>
    <div
      className={'loading'}
      role={'alert'}
      aria-label={`${en.components.loading.ariaPrefix} ${message}`}>
      <div className={'spinner-outer'}>
        <div className={'spinner'} />
      </div>
      <Div>
        <Small>{message}</Small>
      </Div>
    </div>
  </ErrorBoundary>
)

export default Loading
