import { useKeycloak } from '@react-keycloak/web'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { Project } from '../../@types/projects'
import { ReactComponent as DataSourceIcon } from '../../assets/images/data-source-icon.svg'
import { ReactComponent as InsightsIcon } from '../../assets/images/insights-icon.svg'
import { featureFlags } from '../../config/flags'
import { navigationInfo } from '../../config/urls'
import { ProjectsStoreContext } from '../../context/ProjectsStore'
import { en } from '../../locales/en'
import A from '../elements/A'
import Div from '../elements/Div'
import Img from '../elements/Img'
import Li from '../elements/Li'
import Nav from '../elements/Nav'
import Ol from '../elements/Ol'

const Navigation = (): JSX.Element => {
  const {
    dashboard,
    home,
    projects,
    dataSource,
    dataSourceCreate,
    dataElements,
    dataElementsCreate,
    dataExplorerLanding,
    login,
    models,
    training,
    scoring,
    settings
  } = navigationInfo

  const location = useLocation()
  const { keycloak } = useKeycloak()
  const { state } = useContext(ProjectsStoreContext)

  const [showProfile, setShowProfile] = useState(false)
  const [defaultProjectId, setDefaultProjectId] = useState<string | undefined>(
    undefined
  )
  const [orgSlug, setOrgSlug] = useState<string | null>(null)

  useEffect(() => {
    if (!state.fetching && state.items.length > 0) {
      const defaultProject = state.items.find(
        (item: Project) => item.default
      )?.id
      setDefaultProjectId(defaultProject)
      setOrgSlug(state.items[0]?.organization?.slug || null)
    }
  }, [state.items, state.fetching, keycloak.idTokenParsed?.sub])

  const keycloakLogin = useCallback(() => {
    keycloak.login({ redirectUri: process.env.REACT_APP_BASE_URL })
  }, [keycloak])

  const modelsIsActive = () =>
    `/${location.pathname.split('/')[1]}` === models.url

  const dataSourcesIsActive = () =>
    `/${location.pathname.split('/')[1]}` === dataSource.url ||
    `/${location.pathname.split('/')[1]}` === dataSourceCreate.url

  const dataElementsIsActive = () =>
    `/${location.pathname.split('/')[1]}` === dataElements.url ||
    `/${location.pathname.split('/')[1]}` === dataElementsCreate.url

  const dataExplorerIsActive = () =>
    `/${location.pathname.split('/')[1]}` === projects.url ||
    `/${location.pathname.split('/')[1]}` === '/project'

  const projectsIsActive = () =>
    `/${location.pathname.split('/')[1]}` === projects.url ||
    `/${location.pathname.split('/')[1]}` === '/project'

  const settingsIsActive = () =>
    `/${location.pathname.split('/')[1]}` === settings.url ||
    `/${location.pathname.split('/')[1]}` === '/settings'

  const getAvatar = () => {
    // Keycloak do not provide TS definition
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const initialFamilyName =
      keycloak.idTokenParsed?.family_name.charAt(0) || ' '
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const initialGivenName = keycloak.idTokenParsed?.given_name.charAt(0) || ' '
    return `${initialGivenName}${initialFamilyName}`
  }

  const getAuthNavigation = () => (
    <>
      {featureFlags.DASHBOARD && (
        <Li>
          <NavLink to={dashboard.url} exact={true}>
            {dashboard.navigationLink}
          </NavLink>
        </Li>
      )}
      {featureFlags.PROJECTS && (
        <Li>
          <NavLink to={projects.url} exact={true} isActive={projectsIsActive}>
            {projects.navigationLink}
          </NavLink>
        </Li>
      )}
      {featureFlags.DATA_SOURCES_LANDING_MVP && defaultProjectId && (
        <Li>
          <NavLink
            to={dataExplorerLanding.getUrl(defaultProjectId)}
            exact={true}
            isActive={dataExplorerIsActive}
            className={'data-sources'}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            component={({ navigate, ...rest }) => {
              return (
                <a {...rest}>
                  <DataSourceIcon />
                  {dataExplorerLanding.navigationLink}
                </a>
              )
            }}
          />

          <NavLink
            target={'_blank'}
            to={{
              pathname: orgSlug
                ? `https://${orgSlug}${process.env.REACT_APP_IE_URL}`
                : ''
            }}
            exact={true}
            className={'data-sources'}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            component={({ navigate, ...rest }) => {
              return (
                <a {...rest}>
                  <InsightsIcon />
                  {dashboard.title}
                </a>
              )
            }}
          />
        </Li>
      )}
      {featureFlags.DATA_SOURCES && (
        <Li>
          <DataSourceIcon />
          <NavLink
            to={dataSource.url}
            exact={true}
            isActive={dataSourcesIsActive}>
            {dataSource.navigationLink}
          </NavLink>
        </Li>
      )}
      {featureFlags.DATA_ELEMENTS && (
        <Li>
          <NavLink
            to={dataElements.url}
            exact={true}
            isActive={dataElementsIsActive}>
            {dataElements.navigationLink}
          </NavLink>
        </Li>
      )}
      {featureFlags.MODELS && (
        <Li>
          <NavLink to={models.url} exact={true} isActive={modelsIsActive}>
            {models.navigationLink}
          </NavLink>
        </Li>
      )}
      {featureFlags.TRAINING && (
        <Li>
          <NavLink to={training.url} exact={true}>
            {training.navigationLink}
          </NavLink>
        </Li>
      )}
      {featureFlags.SCORING && (
        <Li>
          <NavLink to={scoring.url} exact={true}>
            {scoring.navigationLink}
          </NavLink>
        </Li>
      )}
      {featureFlags.SETTINGS && (
        <Li className={'navigation-settings'}>
          <NavLink to={settings.url} exact={true} isActive={settingsIsActive}>
            {settings.navigationLink}
          </NavLink>
        </Li>
      )}
      <Li className={'navigation-logout'}>
        <A onClick={() => setShowProfile(!showProfile)} avatar={getAvatar()}>
          Profile
        </A>
        {showProfile && (
          <div className={'extra-menu'}>
            {featureFlags.SETTINGS && (
              <NavLink
                to={home.url}
                exact={true}
                onClick={(event) => {
                  event.preventDefault()
                  alert('Profile page')
                  setShowProfile(false)
                }}>
                Profile
              </NavLink>
            )}
            <NavLink
              to={home.url}
              exact={true}
              onClick={() => keycloak.logout()}>
              {en.pages.logout.navLink}
            </NavLink>
          </div>
        )}
      </Li>
    </>
  )

  const getNoAuthNavigation = () => (
    <>
      <Li className={'navigation-logout'}>
        <NavLink to={login.url} exact={true} onClick={keycloakLogin}>
          {login.navigationLink}
        </NavLink>
      </Li>
    </>
  )

  return (
    <Nav>
      <Div align={'centre'} className={'logo-container'}>
        <Img alt={'Logo'} src={'/logo.svg'} />
      </Div>
      <Ol>
        {keycloak?.authenticated ? getAuthNavigation() : getNoAuthNavigation()}
      </Ol>
    </Nav>
  )
}

export default Navigation
