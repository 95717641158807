import { Project, ProjectsActions, ProjectsState } from '../@types/projects'

export const ProjectsInitialState = {
  fetching: false,
  items: [],
  defaultProject: null,
  error: null,
  addData: null
}

export const ProjectsReducer = (
  state: ProjectsState,
  action: ProjectsActions
): ProjectsState => {
  switch (action.type) {
    case 'PROJECTS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      }
    case 'PROJECTS_ERROR':
      return {
        ...state,
        fetching: false,
        items: [],
        error: action.error || null
      }

    case 'PROJECTS_SUCCESS':
      return {
        ...state,
        fetching: false,
        error: null,
        items: action.items || [],
        defaultProject: action.items?.find((item) => item.default)?.id || null
      }
    case 'PROJECTS_ADD_REQUEST':
      return {
        ...state,
        addData: {
          completed: false,
          error: null,
          fetching: true,
          data: action.addData?.data
        }
      } as ProjectsState
    case 'PROJECTS_ADD_SUCCESS':
      return {
        ...state,
        items: [...state.items, ...[action.project as Project]],
        addData: {
          ...state.addData,
          completed: true,
          fetching: false
        }
      }
    case 'PROJECTS_ADD_ERROR':
      return {
        ...state,
        addData: {
          ...state.addData,
          fetching: false,
          error: action.error
        }
      }
    case 'PROJECT_ADD_CLEAR':
      return {
        ...state,
        addData: null
      }
    default:
      return state
  }
}
