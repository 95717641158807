export const dataGrid = {
  columnHeaders: {
    actions: 'Actions',
    created: 'Created',
    creator: 'Creator',
    description: 'Description',
    email: 'Email',
    name: 'Name',
    organization: 'Organisation',
    status: 'Status',
    statusMessage: 'Description'
  },
  filters: {
    toggle: 'Toggle filters'
  },
  search: {
    label: 'Search table',
    placeholder: 'Search...',
    toggleHiddenLink: 'Show search details'
  }
} as const
