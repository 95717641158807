export const homePage = {
  becomeATesterModal: {
    header: 'Become a beta tester'
  },
  requestSentModal: {
    header: 'Request sent',
    content: 'Thanks for submmiting your form. Someone from our team will contact you as soon as possible.',
    backButton: 'Back'
  }
} as const
