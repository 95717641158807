import React from 'react'

import { en } from '../../locales/en'
import VisuallyHidden from '../VisuallyHidden'

interface AProps {
  avatar?: string
  children: React.ReactNode
  className?: string
  dataVal?: string
  download?: boolean
  href?: string
  newWindow?: boolean
  onClick?: (arg?: React.SyntheticEvent) => void
  ctx?:
    | 'add'
    | 'block-wrapper'
    | 'cancel'
    | 'filters'
    | 'layout-grid'
    | 'layout-tile'
    | 'menuStack'
    | 'search'
    | 'sortable'
  sprite?: boolean
  title?: string
  ariaExpanded?: boolean
  ariaControls?: string
}

const A = ({
  avatar,
  children,
  className,
  ctx = undefined,
  dataVal,
  download = false,
  href = undefined,
  newWindow = false,
  onClick = undefined,
  title,
  ariaExpanded,
  ariaControls
}: AProps): JSX.Element => {
  const getCls = () => {
    const cls = []
    if (className) {
      cls.push(className)
    }
    if (ctx === 'cancel') {
      cls.push('a-cancel')
    } else if (ctx === 'add') {
      cls.push('a-add')
    } else if (ctx === 'menuStack') {
      cls.push('menu-stack')
    } else if (ctx === 'sortable') {
      cls.push('a-sortable')
    } else if (ctx === 'filters') {
      cls.push('a-filters')
    } else if (ctx === 'search') {
      cls.push('a-search')
    } else if (ctx === 'layout-grid') {
      cls.push('a-layout-grid')
    } else if (ctx === 'layout-tile') {
      cls.push('a-layout-tile')
    } else if (ctx === 'block-wrapper') {
      cls.push('a-block-wrapper')
    }
    return cls.length === 0 ? undefined : cls.join(' ')
  }

  const targetAndRel = newWindow
    ? {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    : {}

  return (
    <a
      {...targetAndRel}
      href={href}
      download={download || undefined}
      onClick={onClick}
      className={getCls()}
      title={title}
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
      data-avatar={avatar}
      data-val={dataVal}>
      {children}
      {newWindow && (
        <VisuallyHidden>{en.elements.a.hiddenNewWindowText}</VisuallyHidden>
      )}
    </a>
  )
}

export default A
