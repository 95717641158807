import React from 'react'

interface ImgProps {
    alt: string
    src: string
}

const Img = ({ alt, src } : ImgProps) : JSX.Element => (
    <img src={src} alt={alt} />
)

export default Img
