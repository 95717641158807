export const errors = {
  1201: {
    message: 'Keycloak error creating user'
  },
  1202: {
    message: 'Keycloak error deleting user'
  },
  1203: {
    message: 'Keycloak can not delete all the users outside test environment'
  },
  1204: {
    message: 'Keycloak error trying to delete all the users'
  },
  1205: {
    message: 'Keycloak error sending password by email'
  },
  1501: {
    message: 'Airbyte default workspace not found'
  },
  1502: {
    message: 'Airbyte error creating source connection'
  },
  1503: {
    message: 'Airbyte S3 destination definition not found'
  },
  1504: {
    message: 'Airbyte error trying to connect to S3 destination'
  },
  1505: {
    message: 'Airbyte error creating data source'
  },
  1701: {
    message: 'Mailchimp error sending message'
  }
} as const
