import React from 'react'

import ErrorBoundary from '../ErrorBoundary'

interface DivProps {
    align?: 'left' | 'centre' | 'right' | null
    box?: 'box' | 'boxVertical' | 'boxHorizontal' | 'boxTop'
    children: React.ReactNode
    className?: string
    id?: string
    role?: string
    space?: 'space' | 'spaceVertical' | 'spaceVerticalXL' | 'spaceHorizontal' | 'spaceTop' | 'spaceBottom'
    title?: string
}

const Div = ({ box, align, children, className, id = undefined, role, space, title } : DivProps) : JSX.Element => {
  const getCls = () => {
    const cls = []
    if (className) {
      cls.push(className)
    }
    if (box) {
      if (box === 'box') {
        cls.push('box')
      } else if (box === 'boxVertical') {
        cls.push('box-vertical')
      } else if (box === 'boxHorizontal') {
        cls.push('box-horizontal')
      } else if (box === 'boxTop') {
        cls.push('box-top')
      }
    }
    if (space) {
      if (space === 'space') {
        cls.push('space')
      } else if (space === 'spaceVertical') {
        cls.push('space-vertical')
      } else if (space === 'spaceVerticalXL') {
        cls.push('space-vertical-xl')
      } else if (space === 'spaceHorizontal') {
        cls.push('space-horizontal')
      } else if (space === 'spaceTop') {
        cls.push('space-top')
      } else if (space === 'spaceBottom') {
        cls.push('space-bottom')
      }
    }
    if (align) {
      cls.push(align === 'centre' ? 'align-centre' : align === 'right' ? 'align-right' : 'align-left')
    }
    return cls.length > 0 ? cls.join(' ') : undefined
  }

  return (
        <ErrorBoundary>
            <div role={role || undefined} className={getCls()} id={id} title={title}>{children}</div>
        </ErrorBoundary>
  )
}

export default Div
