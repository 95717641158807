export const projectPage = {
  links: {
    dataSource: 'Data source',
    dataSources: 'Data sources',
    dataSourcesGo: 'Select Data Sources',
    dataSourcesGoMore: 'Select more Data sources',
    dataElements: 'Data elements',
    dataElementsGo: 'Go to Data Elements',
    models: 'Models',
    modelsGo: 'Go to Models'
  }
} as const
