import React, { createContext, useEffect, useReducer } from 'react'

import { User, UsersActions, UsersState } from '../@types/users'
import { useAxios } from '../hooks/useAxios'
import { UsersInitialState, UsersReducer } from '../reducers/UsersReducer'

interface UsersStoreProps {
  children: React.ReactNode
}

interface ContextProps {
  state: UsersState
  dispatch: React.Dispatch<UsersActions>
}

const UsersStore = ({ children }: UsersStoreProps): JSX.Element => {
  const [state, dispatch] = useReducer(UsersReducer, UsersInitialState)

  const axiosInstance = useAxios()

  useEffect(() => {
    dispatch({ type: 'USERS_REQUEST' })
    axiosInstance.current
      ?.get('/api/v1/users')
      .then((res) => {
        dispatch({ type: 'USERS_SUCCESS', items: res.data as User[] })
      })
      .catch((error) => {
        dispatch({ type: 'USERS_ERROR', error })
      })
  }, [axiosInstance])

  useEffect(() => {
    if (state.params) {
      dispatch({ type: 'USERS_REQUEST' })
      axiosInstance.current
        ?.get('/api/v1/users', { params: state.params })
        .then((res) => {
          dispatch({ type: 'USERS_SUCCESS', items: res.data as User[] })
        })
        .catch((error) => {
          dispatch({ type: 'USERS_ERROR', error })
        })
    }
  }, [axiosInstance, state.params])

  useEffect(() => {
    if (state.addData && !state.addData.error && !state.addData.completed) {
      axiosInstance.current
        ?.post('/api/v1/invite', state.addData.data)
        .then((res) => {
          dispatch({ type: 'USER_ADD_SUCCESS', user: res.data as User })
        })
        .catch((error) => {
          dispatch({ type: 'USER_ADD_ERROR', error })
        })
    }
  }, [axiosInstance, state.addData])

  return (
    <UsersStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </UsersStoreContext.Provider>
  )
}

export const UsersStoreContext = createContext<ContextProps>({} as ContextProps)

export default UsersStore
