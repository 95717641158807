import { en } from '../locales/en'

type NavigationPages =
  | 'betaTestingTrialLicenceOrder'
  | 'home'
  | 'dataProcessingAgreement'
  | 'masterLicenceAndServicesAgreement'
  | 'dashboard'
  | 'dataElements'
  | 'dataElementsCreate'
  | 'dataExplorerEdit'
  | 'dataExplorerLanding'
  | 'dataExplorerSources'
  | 'dataExplorerConfirm'
  | 'dataExplorerConfigure'
  | 'dataExplorerPending'
  | 'dataSource'
  | 'dataSourceCreate'
  | 'login'
  | 'models'
  | 'model'
  | 'project'
  | 'projectDataSources'
  | 'projectDataElements'
  | 'projectModels'
  | 'projectModel'
  | 'projectModelCreate'
  | 'projectModelEdit'
  | 'projects'
  | 'resources'
  | 'training'
  | 'scoring'
  | 'settings'
  | 'user'
  | 'users'

interface PageInfo {
  readonly navigationLink: string
  readonly url: string
  readonly title: string
  getUrl: (arg1?: string, arg2?: string) => string
}

export const navigationInfo: Record<NavigationPages, PageInfo> = {
  home: {
    navigationLink: en.navigationInfo.home.link,
    url: '/',
    title: en.navigationInfo.home.title,
    getUrl: () => '/'
  },
  dataProcessingAgreement: {
    navigationLink: en.navigationInfo.dataProcessingAgreement.link,
    url: '/data-processing-agreement',
    title: en.navigationInfo.dataProcessingAgreement.title,
    getUrl: () => '/data-processing-agreement'
  },
  masterLicenceAndServicesAgreement: {
    navigationLink: en.navigationInfo.masterLicenceAndServicesAgreement.link,
    url: '/master-licence-and-services-agreement',
    title: en.navigationInfo.masterLicenceAndServicesAgreement.title,
    getUrl: () => '/master-licence-and-services-agreement'
  },
  betaTestingTrialLicenceOrder: {
    navigationLink: en.navigationInfo.betaTestingTrialLicenceOrder.link,
    url: '/beta-testing-trial-licence-order',
    title: en.navigationInfo.betaTestingTrialLicenceOrder.title,
    getUrl: () => '/beta-testing-trial-licence-order'
  },
  dashboard: {
    navigationLink: en.navigationInfo.dashboard.link,
    url: '/dashboard',
    title: en.navigationInfo.dashboard.title,
    getUrl: () => '/dashboard'
  },
  dataSource: {
    navigationLink: en.navigationInfo.dataSource.link,
    url: '/data-source',
    title: en.navigationInfo.dataSource.title,
    getUrl: () => '/data-source'
  },
  dataSourceCreate: {
    navigationLink: en.navigationInfo.dataSourcesCreate.title,
    url: '/project/:projectId/data-source/create',
    title: en.navigationInfo.dataSourcesCreate.title,
    getUrl: (projectId) => `/project/${projectId}/data-source/create`
  },
  dataElements: {
    navigationLink: en.navigationInfo.dataElements.link,
    url: '/data-elements',
    title: en.navigationInfo.dataElements.title,
    getUrl: () => '/data-elements'
  },
  dataElementsCreate: {
    navigationLink: en.navigationInfo.dataElementsCreate.title,
    url: '/project/:projectId/data-elements/create',
    title: en.navigationInfo.dataElementsCreate.title,
    getUrl: (projectId) => `/project/${projectId}/data-elements/create`
  },
  dataExplorerEdit: {
    navigationLink: en.navigationInfo.dataExplorerEdit.title,
    url: '/project/:projectId/data/:dataSourceId/edit',
    title: en.navigationInfo.dataExplorerEdit.title,
    getUrl: (projectId, dataSourceId) =>
      `/project/${projectId}/data/${dataSourceId}/edit`
  },
  dataExplorerLanding: {
    navigationLink: en.navigationInfo.dataExplorer.title,
    url: '/project/:projectId/data',
    title: en.navigationInfo.dataExplorer.title,
    getUrl: (projectId) => `/project/${projectId}/data`
  },
  dataExplorerSources: {
    navigationLink: en.navigationInfo.dataExplorerSources.title,
    url: '/project/:projectId/data/sources',
    title: en.navigationInfo.dataExplorerSources.title,
    getUrl: (projectId) => `/project/${projectId}/data/sources`
  },
  dataExplorerConfirm: {
    navigationLink: en.navigationInfo.dataExplorerConfirm.title,
    url: '/project/:projectId/data/confirm',
    title: en.navigationInfo.dataExplorerConfirm.title,
    getUrl: (projectId) => `/project/${projectId}/data/confirm`
  },
  dataExplorerConfigure: {
    navigationLink: en.navigationInfo.dataExplorerConfigure.title,
    url: '/project/:projectId/data/configure',
    title: en.navigationInfo.dataExplorerConfigure.title,
    getUrl: (projectId) => `/project/${projectId}/data/configure`
  },
  dataExplorerPending: {
    navigationLink: en.navigationInfo.dataExplorerPending.title,
    url: '/project/:projectId/data/status',
    title: en.navigationInfo.dataExplorerPending.title,
    getUrl: (projectId) => `/project/${projectId}/data/status`
  },
  login: {
    navigationLink: en.navigationInfo.login.link,
    url: '/login',
    title: en.navigationInfo.login.title,
    getUrl: () => '/login'
  },
  models: {
    navigationLink: en.navigationInfo.models.link,
    url: '/models',
    title: en.navigationInfo.models.title,
    getUrl: () => '/models'
  },
  model: {
    navigationLink: en.navigationInfo.model.link,
    url: '/models/:modelId',
    title: en.navigationInfo.model.title,
    getUrl: (modelId) => `/models/${modelId}`
  },
  project: {
    navigationLink: en.navigationInfo.project.link,
    url: '/project/:projectId',
    title: en.navigationInfo.project.title,
    getUrl: (projectId) => `/project/${projectId}`
  },
  projects: {
    navigationLink: en.navigationInfo.projects.link,
    url: '/projects',
    title: en.navigationInfo.projects.title,
    getUrl: () => '/projects'
  },
  projectDataSources: {
    navigationLink: en.navigationInfo.projectDataSources.link,
    url: '/project/:projectId/data-sources',
    title: en.navigationInfo.projectDataSources.title,
    getUrl: (projectId) => `/project/${projectId}/data-sources`
  },
  projectDataElements: {
    navigationLink: en.navigationInfo.projectDataElements.link,
    url: '/project/:projectId/data-elements',
    title: en.navigationInfo.projectDataElements.title,
    getUrl: (projectId) => `/project/${projectId}/data-elements`
  },
  projectModels: {
    navigationLink: en.navigationInfo.projectModels.link,
    url: '/project/:projectId/models',
    title: en.navigationInfo.projectModels.title,
    getUrl: (projectId) => `/project/${projectId}/models`
  },
  projectModel: {
    navigationLink: en.navigationInfo.projectModel.link,
    url: '/project/:projectId/models/:modelId/view',
    title: en.navigationInfo.projectModel.title,
    getUrl: (projectId, modelId) =>
      `/project/${projectId}/models/${modelId}/view`
  },
  projectModelCreate: {
    navigationLink: en.navigationInfo.modelCreate.link,
    url: '/project/:projectId/models/create',
    title: en.navigationInfo.modelCreate.title,
    getUrl: (projectId) => `/project/${projectId}/models/create`
  },
  projectModelEdit: {
    navigationLink: en.navigationInfo.modelEdit.link,
    url: '/project/:projectId/models/:modelId/edit',
    title: en.navigationInfo.modelEdit.title,
    getUrl: (projectId, modelId) =>
      `/project/${projectId}/models/${modelId}/edit`
  },
  resources: {
    navigationLink: en.navigationInfo.resources.title,
    url: '/settings/resources',
    title: en.navigationInfo.resources.title,
    getUrl: () => '/settings/resources'
  },
  training: {
    navigationLink: en.navigationInfo.training.title,
    url: '/training',
    title: en.navigationInfo.training.title,
    getUrl: () => '/training'
  },
  scoring: {
    navigationLink: en.navigationInfo.scoring.title,
    url: '/scoring',
    title: en.navigationInfo.scoring.title,
    getUrl: () => '/scoring'
  },
  settings: {
    navigationLink: en.navigationInfo.settings.title,
    url: '/settings',
    title: en.navigationInfo.settings.title,
    getUrl: () => '/settings'
  },
  user: {
    navigationLink: en.navigationInfo.user.title,
    url: '/settings/users/:userId',
    title: en.navigationInfo.user.title,
    getUrl: (userId) => `/settings/users/${userId}`
  },
  users: {
    navigationLink: en.navigationInfo.users.title,
    url: '/settings/users',
    title: en.navigationInfo.users.title,
    getUrl: () => '/settings/users'
  }
} as const
