import React, { createContext, useReducer } from 'react'

import { DataExplorerActions, DataExplorerState } from '../@types/dataExplorer'
import {
  DataExplorerInitialState,
  DataExplorerReducer
} from '../reducers/DataExplorerReducer'

interface DataExplorerStoreProps {
  children: React.ReactNode
}

interface ContextProps {
  stateDataExplorer: DataExplorerState
  dispatchDataExplorer: React.Dispatch<DataExplorerActions>
}

const DataExplorerStore = ({
  children
}: DataExplorerStoreProps): JSX.Element => {
  const [state, dispatch] = useReducer(
    DataExplorerReducer,
    DataExplorerInitialState
  )

  return (
    <DataExplorerStoreContext.Provider
      value={{ stateDataExplorer: state, dispatchDataExplorer: dispatch }}>
      {children}
    </DataExplorerStoreContext.Provider>
  )
}

export const DataExplorerStoreContext = createContext<ContextProps>(
  {} as ContextProps
)

export default DataExplorerStore
