import React, { createContext, useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'

import {
  DataElement,
  DataElementsActions,
  DataElementsState
} from '../@types/dataElements'
import { ProjectRouteProps } from '../@types/projects'
import { featureFlags } from '../config/flags'
import { useAxios } from '../hooks/useAxios'
import {
  DataElementsInitialState,
  DataElementsReducer
} from '../reducers/DataElementsReducer'

interface DataElementsStoreProps {
  children: React.ReactNode
}

interface ContextProps {
  stateDataElement: DataElementsState
  dispatchDataElement: React.Dispatch<DataElementsActions>
}

const DataElementsStore = ({
  children
}: DataElementsStoreProps): JSX.Element => {
  const axiosInstance = useAxios()

  const [state, dispatch] = useReducer(
    DataElementsReducer,
    DataElementsInitialState
  )

  const { projectId } = useParams<ProjectRouteProps>()

  useEffect(() => {
    if (featureFlags.DATA_ELEMENTS) {
      dispatch({ type: 'DATA_ELEMENTS_REQUEST' })
      const param = projectId ? `?projectId=${projectId}` : ''

      axiosInstance.current
        ?.get(`/api/v1/dataElements/${param}`)
        .then((res) => {
          dispatch({
            type: 'DATA_ELEMENTS_SUCCESS',
            items: res.data as DataElement[]
          })
        })
        .catch((error) => {
          dispatch({ type: 'DATA_ELEMENTS_ERROR', error: error })
        })
    }
  }, [axiosInstance, projectId])

  useEffect(() => {
    if (featureFlags.DATA_ELEMENTS) {
      if (state.extendData?.completed) {
        dispatch({ type: 'DATA_ELEMENT_EXTEND_CLEAR' })
      }
    }
  }, [state.extendData?.completed])

  useEffect(() => {
    if (featureFlags.DATA_ELEMENTS) {
      if (
        state.extendData &&
        !state.extendData.fetching &&
        !state.extendData.error &&
        !state.extendData.completed
      ) {
        dispatch({ type: 'DATA_ELEMENT_EXTEND_REQUEST' })
        axiosInstance.current
          ?.get(`/api/v1/dataElements/${state.extendData.id}`)
          .then((res) => {
            dispatch({
              type: 'DATA_ELEMENT_EXTEND_SUCCESS',
              id: state.extendData?.id,
              versions: res.data.versions
            })
          })
          .catch((error) => {
            dispatch({ type: 'DATA_ELEMENT_EXTEND_ERROR', error: error })
          })
      }
    }
  }, [axiosInstance, state.extendData])

  useEffect(() => {
    if (featureFlags.DATA_ELEMENTS) {
      if (state.addData && !state.addData.error && !state.addData.completed) {
        axiosInstance.current
          ?.post('/api/v1/dataElements', state?.addData?.data)
          .then((res) => {
            dispatch({
              type: 'DATA_ELEMENTS_ADD_SUCCESS',
              dataElement: res.data as DataElement
            })
          })
          .catch((error) => {
            dispatch({ type: 'DATA_ELEMENTS_ADD_ERROR', error })
          })
      }
    }
  }, [axiosInstance, state.addData, projectId])

  return (
    <DataElementsStoreContext.Provider
      value={{ stateDataElement: state, dispatchDataElement: dispatch }}>
      {children}
    </DataElementsStoreContext.Provider>
  )
}

export const DataElementsStoreContext = createContext<ContextProps>(
  {} as ContextProps
)

export default DataElementsStore
