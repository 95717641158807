import AmazonAdvertising from './svg/AmazonAdvertising.svg'
import AmazonSellerPartner from './svg/AmazonSellerPartner.svg'
import Amplitude from './svg/Amplitude.svg'
import ApifyDataset from './svg/ApifyDataset.svg'
import Asana from './svg/Asana.svg'
import AWSCloudTrail from './svg/AWSCloudTrail.svg'
import BambooHR from './svg/BambooHR.svg'
import BingAds from './svg/BingAds.svg'
import Cart from './svg/Cart.svg'
import Chargebee from './svg/Chargebee.svg'
import Clickhouse from './svg/Clickhouse.svg'
import CockroachDB from './svg/CockroachDB.svg'
import Gitlab from './svg/Gitlab.svg'
import GoogleAds from './svg/GoogleAds.svg'
import GoogleSearchConsole from './svg/GoogleSearchConsole.svg'
import GoogleWorkspaceAdminReports from './svg/GoogleWorkspace.svg'
import IBMDB2 from './svg/IBMDB2.svg'
import Instagram from './svg/instagram.svg'
import IterableIcon from './svg/Iterable.svg'
import Klaviyo from './svg/Klaviyo.svg'
import Okta from './svg/Okta.svg'
import OracleDB from './svg/OracleDB.svg'
import PaypalTransaction from './svg/Paypal.svg'
import Pipedrive from './svg/Pipedrive.svg'
import Prestashop from './svg/Prestashop.svg'
import Quickbooks from './svg/Quickbooks.svg'
import Shopify from './svg/Shopify.svg'
import ShortIO from './svg/ShortIO.svg'
import Smartsheets from './svg/Smartsheets.svg'
import SnapchatMarketing from './svg/SnapchatMarketing.svg'
import Snowflake from './svg/Snowflake.svg'
import Square from './svg/Square.svg'
import SurveyMonkey from './svg/SurveyMonkey.svg'
import Trello from './svg/Trello.svg'
import Twilio from './svg/Twilio.svg'
import Typeform from './svg/Typeform.svg'
import ZendeskSunshine from './svg/ZendeskSunshine.svg'
import ZendeskTalk from './svg/ZendeskTalk.svg'
import Zuora from './svg/Zuora.svg'

export const AirbyteSupportedNames = {
  mailchimp: 'Mailchimp',
  shopify: 'Shopify',
  surveyMonkey: 'Survey Monkey'
}

export const AirbyteUnSupportedNames = {
  amazonAds: 'Amazon Ads',
  amazonSellerPartner: 'Amazon Seller Partner',
  amplitude: 'Amplitude',
  apifyDataset: 'Apify Dataset',
  appstore: 'Appstore',
  asana: 'Asana',
  AWSCloudTrail: 'AWS CloudTrail',
  bambooHR: 'BambooHR',
  bigQuery: 'BigQuery',
  bingAds: 'Bing Ads',
  braintree: 'Braintree',
  cart: 'Cart.com',
  chargebee: 'Chargebee',
  clickHouse: 'ClickHouse',
  cockroachdb: 'Cockroachdb',
  dixa: 'Dixa',
  drift: 'Drift',
  exchangeRatesApi: 'Exchange Rates Api',
  facebookMarketing: 'Facebook Marketing',
  file: 'File',
  freshdesk: 'Freshdesk',
  gitHub: 'GitHub',
  gitlab: 'Gitlab',
  googleAds: 'Google Ads',
  googleAdwords: 'Google Adwords (Deprecated)',
  googleAnalytics: 'Google Analytics',
  googleAnalyticsV4: 'Google Analytics v4',
  googleDirectory: 'Google Directory',
  googleSearchConsole: 'Google Search Console',
  googleSheets: 'Google Sheets',
  googleWorkspaceAdminReports: 'Google Workspace Admin Reports',
  greenhouse: 'Greenhouse',
  harvest: 'Harvest',
  hubspot: 'Hubspot',
  IBMDb2: 'IBM Db2',
  instagram: 'Instagram',
  intercom: 'Intercom',
  iterable: 'Iterable',
  jira: 'Jira',
  klaviyo: 'Klaviyo',
  looker: 'Looker',
  mailchimp: 'Mailchimp',
  marketo: 'Marketo',
  microsoftSQLServer: 'Microsoft SQL Server (MSSQL)',
  microsoftTeams: 'Microsoft teams',
  mixpanel: 'Mixpanel',
  mongoDB: 'Mongo DB',
  mySQL: 'MySQL',
  okta: 'Okta',
  oracleDB: 'Oracle DB',
  paypalTransaction: 'Paypal Transaction',
  pipedrive: 'Pipedrive',
  plaid: 'Plaid',
  pokeAPI: 'PokeAPI',
  postgres: 'Postgres',
  postHog: 'PostHog',
  prestashop: 'Prestashop',
  quickbooks: 'Quickbooks',
  recharge: 'Recharge',
  recurly: 'Recurly',
  redshift: 'Redshift',
  S3: 'S3',
  salesforce: 'Salesforce',
  sendgrid: 'Sendgrid',
  shopify: 'Shopify',
  shortIO: 'Short.io',
  slack: 'Slack',
  smartsheets: 'Smartsheets',
  snapchatMarketing: 'Snapchat Marketing',
  snowflake: 'Snowflake',
  square: 'Square',
  stripe: 'Stripe',
  tempo: 'Tempo',
  trello: 'Trello',
  twilio: 'Twilio',
  typeform: 'Typeform',
  USCensus: 'US Census',
  zendeskChat: 'Zendesk Chat',
  zendeskSunshine: 'Zendesk Sunshine',
  zendeskSupport: 'Zendesk Support',
  zendeskTalk: 'Zendesk Talk',
  zoom: 'Zoom',
  zuora: 'Zuora'
}

export const getBackUpAirbyteIcon = (name: string): string | boolean => {
  const backups = new Map()
  backups.set(AirbyteUnSupportedNames.amazonSellerPartner, AmazonSellerPartner)
  backups.set(AirbyteUnSupportedNames.amazonAds, AmazonAdvertising)
  backups.set(AirbyteUnSupportedNames.amplitude, Amplitude)
  backups.set(AirbyteUnSupportedNames.apifyDataset, ApifyDataset)
  backups.set(AirbyteUnSupportedNames.asana, Asana)
  backups.set(AirbyteUnSupportedNames.AWSCloudTrail, AWSCloudTrail)
  backups.set(AirbyteUnSupportedNames.bambooHR, BambooHR)
  backups.set(AirbyteUnSupportedNames.bingAds, BingAds)
  backups.set(AirbyteUnSupportedNames.cart, Cart)
  backups.set(AirbyteUnSupportedNames.chargebee, Chargebee)
  backups.set(AirbyteUnSupportedNames.clickHouse, Clickhouse)
  backups.set(AirbyteUnSupportedNames.cockroachdb, CockroachDB)
  backups.set(AirbyteUnSupportedNames.gitlab, Gitlab)
  backups.set(AirbyteUnSupportedNames.googleAds, GoogleAds)
  backups.set(AirbyteUnSupportedNames.googleSearchConsole, GoogleSearchConsole)
  backups.set(AirbyteUnSupportedNames.googleWorkspaceAdminReports, GoogleWorkspaceAdminReports)
  backups.set(AirbyteUnSupportedNames.IBMDb2, IBMDB2)
  backups.set(AirbyteUnSupportedNames.instagram, Instagram)
  backups.set(AirbyteUnSupportedNames.iterable, IterableIcon)
  backups.set(AirbyteUnSupportedNames.klaviyo, Klaviyo)
  backups.set(AirbyteUnSupportedNames.okta, Okta)
  backups.set(AirbyteUnSupportedNames.oracleDB, OracleDB)
  backups.set(AirbyteUnSupportedNames.paypalTransaction, PaypalTransaction)
  backups.set(AirbyteUnSupportedNames.pipedrive, Pipedrive)
  backups.set(AirbyteUnSupportedNames.prestashop, Prestashop)
  backups.set(AirbyteUnSupportedNames.quickbooks, Quickbooks)
  backups.set(AirbyteUnSupportedNames.shortIO, ShortIO)
  backups.set(AirbyteUnSupportedNames.smartsheets, Smartsheets)
  backups.set(AirbyteUnSupportedNames.snapchatMarketing, SnapchatMarketing)
  backups.set(AirbyteUnSupportedNames.snowflake, Snowflake)
  backups.set(AirbyteUnSupportedNames.square, Square)
  backups.set(AirbyteSupportedNames.shopify, Shopify)
  backups.set(AirbyteSupportedNames.surveyMonkey, SurveyMonkey)
  backups.set(AirbyteUnSupportedNames.trello, Trello)
  backups.set(AirbyteUnSupportedNames.twilio, Twilio)
  backups.set(AirbyteUnSupportedNames.typeform, Typeform)
  backups.set(AirbyteUnSupportedNames.zendeskSunshine, ZendeskSunshine)
  backups.set(AirbyteUnSupportedNames.zendeskTalk, ZendeskTalk)
  backups.set(AirbyteUnSupportedNames.zuora, Zuora)
  return backups.has(name) ? backups.get(name) : false
}

export const getAirbyteHelpPDF = (name: string): string | boolean => {
  const pdfBaseUrl = '/pdf'
  const backups = new Map()
  backups.set(AirbyteUnSupportedNames.amazonSellerPartner, `${pdfBaseUrl}/AmazonSellerPartner.pdf`)
  backups.set(AirbyteUnSupportedNames.facebookMarketing, `${pdfBaseUrl}/FacebookMarketing.pdf`)
  backups.set(AirbyteUnSupportedNames.googleAds, `${pdfBaseUrl}/GoogleAds.pdf`)
  backups.set(AirbyteUnSupportedNames.googleAnalyticsV4, `${pdfBaseUrl}/GoogleAnalyticsV4.pdf`)
  backups.set(AirbyteSupportedNames.mailchimp, `${pdfBaseUrl}/MailchimpV2.pdf`)
  backups.set(AirbyteUnSupportedNames.salesforce, `${pdfBaseUrl}/Salesforce.pdf`)
  backups.set(AirbyteSupportedNames.shopify, `${pdfBaseUrl}/ShopifyV2.pdf`)
  backups.set(AirbyteSupportedNames.surveyMonkey, `${pdfBaseUrl}/SurveyMonkeyV2.pdf`)
  backups.set(AirbyteUnSupportedNames.zendeskChat, `${pdfBaseUrl}/ZendeskChat.pdf`)
  return backups.has(name) ? backups.get(name) : false
}
