import { Limit } from '../@types/dataElementDesigner'
import { DataSource } from '../@types/dataSources'

export interface DataElementDesignerState {
  progress: number
  name: string
  from: DataSource[]
  diagramJson: string
  saveAsDraft: boolean
  transformations: string[]
  limit?: Limit
  columns: string[]
}

export interface DataElementDesignerActions {
  type:
    | 'SET_NAME'
    | 'SET_PROGRESS'
    | 'ADD_FROM'
    | 'ADD_TRANSFORMATION'
    | 'ADD_LIMIT'
    | 'RESET'
    | 'SET_JSON'
    | 'SAVE'
  name?: string
  from?: DataSource
  transformation?: string
  diagramJson?: string
  limit?: Limit
  progress?: number
}

export const DataElementDesignerInitialState = {
  progress: 1,
  name: '',
  from: [],
  transformations: [],
  diagramJson: '',
  saveAsDraft: true,
  limit: null,
  columns: ['ALL']
}

export const DataElementDesignerReducer = (
  state: DataElementDesignerState,
  action: DataElementDesignerActions
): DataElementDesignerState => {
  switch (action.type) {
    case 'SET_PROGRESS':
      return {
        ...state,
        progress: action.progress as number
      }
    case 'SET_NAME':
      return {
        ...state,
        name: action.name || ''
      }
    case 'ADD_FROM':
      return {
        ...state,
        from: [...state.from, ...[action.from as DataSource]]
      }
    case 'ADD_TRANSFORMATION':
      return {
        ...state,
        transformations: [
          ...state.transformations,
          ...[action.transformation as string]
        ]
      }
    case 'ADD_LIMIT':
      return {
        ...state,
        limit: action.limit as Limit
      }
    case 'SET_JSON':
      return {
        ...state,
        diagramJson: action.diagramJson || ''
      }
    case 'SAVE': {
      return {
        ...state
      }
    }
    case 'RESET':
      return {
        ...(DataElementDesignerInitialState as DataElementDesignerState)
      }
    default:
      return state
  }
}
