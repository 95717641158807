import {
  DataElement,
  DataElementsActions,
  DataElementsState
} from '../@types/dataElements'

export const DataElementsInitialState = {
  fetching: false,
  items: [],
  error: null,
  addData: null
}

export const DataElementsReducer = (
  state: DataElementsState,
  action: DataElementsActions
): DataElementsState => {
  switch (action.type) {
    case 'DATA_ELEMENTS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      }
    case 'DATA_ELEMENTS_SUCCESS':
      return {
        ...state,
        fetching: false,
        items: action.items || []
      }
    case 'DATA_ELEMENTS_ERROR':
      return {
        ...state,
        fetching: false,
        error: action.error || null
      }
    case 'DATA_ELEMENT_EXTEND_SET':
      return {
        ...state,
        extendData: {
          id: action.id as string
        }
      }
    case 'DATA_ELEMENT_EXTEND_REQUEST':
      return {
        ...state,
        extendData: {
          ...state.extendData,
          fetching: true,
          error: null,
          completed: false
        }
      }
    case 'DATA_ELEMENT_EXTEND_SUCCESS':
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.id
            ? { ...item, ...{ versions: action.versions } }
            : item
        ),
        extendData: {
          ...state.extendData,
          fetching: false,
          completed: true
        }
      }
    case 'DATA_ELEMENT_EXTEND_ERROR':
      return {
        ...state,
        extendData: {
          ...state.extendData,
          fetching: false,
          error: action.error
        }
      }
    case 'DATA_ELEMENT_EXTEND_CLEAR':
      return {
        ...state,
        extendData: null
      }
    case 'DATA_ELEMENTS_ADD_REQUEST':
      return {
        ...state,
        addData: {
          completed: false,
          error: null,
          fetching: true,
          data: action.addData?.data
        }
      }
    case 'DATA_ELEMENTS_ADD_SUCCESS':
      return {
        ...state,
        items: [...state.items, ...[action.dataElement as DataElement]],
        addData: {
          ...state.addData,
          completed: true,
          fetching: false
        }
      } as DataElementsState
    case 'DATA_ELEMENTS_ADD_ERROR':
      return {
        ...state,
        addData: {
          ...state.addData,
          fetching: false,
          error: action.error
        }
      }
    case 'DATA_ELEMENTS_ADD_CLEAR':
      return {
        ...state,
        addData: null
      }
    default:
      return state
  }
}
