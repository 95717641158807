import React from 'react'

interface H2Props {
    children: React.ReactNode
    className?: string
    id?: string
    ctx?: 'modal' | 'tagline'
}

const H2 = ({ children, className, ctx = undefined, id = undefined } : H2Props) : JSX.Element => {
  const getCls = () => {
    const cls = []
    if (ctx) {
      if (ctx === 'modal') {
        cls.push('h2-card')
      } else if (ctx === 'tagline') {
        cls.push('h2-tagline')
      }
    }
    if (className) {
      cls.push(className)
    }
    return cls.length === 0 ? undefined : cls.join(' ')
  }

  return (
        <h2 id={id} className={getCls()}>{children}</h2>
  )
}

export default H2
