import React, { useContext } from 'react'

import { Notification } from '../@types/notifications'
import { NotificationsStoreContext } from '../context/NotificationsStore'
import { en } from '../locales/en'

import A from './elements/A'
import H4 from './elements/H4'
import Li from './elements/Li'
import VisuallyHidden from './VisuallyHidden'

interface NotificationProps extends Notification {
  index: number
}

const NotificationItem = ({
  header,
  id,
  index,
  message,
  type = 'default'
}: NotificationProps): JSX.Element => {
  const { dispatchNotifications } = useContext(NotificationsStoreContext)

  return (
    <Li
      className={`notification notification-${type}`}
      notificationIndex={index}>
      <div className={'notification-body'}>
        <A
          onClick={(event) => {
            event?.stopPropagation()
            dispatchNotifications({
              type: 'NOTIFICATION_REMOVE',
              notificationId: id
            })
          }}
          ctx={'cancel'}>
          <VisuallyHidden>{en.components.modal.close}</VisuallyHidden>
        </A>
        <H4>{header}</H4>
        <div>{message}</div>
      </div>
    </Li>
  )
}

export default NotificationItem
