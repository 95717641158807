import React from 'react'

interface H4Props {
    children: React.ReactNode
}

const H4 = ({ children } : H4Props) : JSX.Element => (
    <h4>{children}</h4>
)

export default H4
