export const dataElementDesigner = {
  actions: {
    clearAll: 'Clear',
    revertToSaved: 'Revert',
    saveToApi: 'Save',
    saveToCorePlatform: 'Ingest'
  },
  progress: {
    name: 'Name',
    feed: 'Data',
    columns: 'Columns',
    limits: 'Limits',
    transformations: 'Transformations',
    ingest: 'Finish'
  },
  sidebar: {
    nameLabel: 'Name',
    nodes: {
      columns: {
        header: 'Select',
        label: 'Columns',
        name: 'Columns'
      },
      compare: {
        header: 'Compare'
      },
      count: {
        header: 'Count',
        label: 'Count'
      },
      distinct: {
        header: 'Distinct',
        label: 'Distinct'
      },
      from: {
        header: 'From',
        dataElements: {
          header: 'Data elements',
          empty: 'No data elements',
          fetching: 'Fetching Data Elements'
        },
        dataSources: {
          header: 'Data sources',
          empty: 'No data sources',
          fetching: 'Fetching Data Sources'
        },
        fetchingVersions: 'Fetching versions'
      },
      limit: {
        header: 'Limit',
        label: 'Limit',
        field: {
          header: 'Limit picker',
          lastLabel: 'Last',
          lastValLabel: 'Last val',
          lastTimescaleLabel: 'Timescale',
          lastTimescaleEmpty: 'Select timescale...',
          lastTimescaleDay: 'Day',
          lastTimescaleWeek: 'Week',
          lastTimescaleMonth: 'Month',
          latestLabel: 'Latest',
          allLabel: 'All',
          rangeLabel: 'Range',
          rangeFrom: 'From',
          rangeTo: 'To',
          rowHeader: 'Rows',
          rowAll: 'All',
          rowOne: '1'
        }
      },
      mapping: {
        header: 'Map'
      },
      merge: {
        header: 'Merge'
      },
      script: {
        header: 'Run script'
      },
      transformCase: {
        header: 'Transform case',
        labelTitleCase: 'Title case',
        labelUpperCase: 'Upper case',
        labelLowerCase: 'Lower case'
      },
      where: {
        header: 'Where'
      }
    }
  }
} as const
