import { useKeycloak } from '@react-keycloak/web'
import axios from 'axios'
import type { AxiosInstance } from 'axios'
import { useEffect, useRef, MutableRefObject } from 'react'

export const useAxios = (): MutableRefObject<AxiosInstance | undefined> => {
  const axiosInstance = useRef<AxiosInstance>()
  const { keycloak, initialized } = useKeycloak()

  useEffect(() => {
    if (!keycloak.authenticated) {
      return
    }

    axiosInstance.current = axios.create()

    axiosInstance.current.interceptors.request.use((config) => ({
      ...config,
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        ...config.headers,
        Authorization: initialized ? `Bearer ${keycloak.token}` : undefined
      }
    }))

    axiosInstance.current.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        const res = error.response
        if (res.status === 401) {
          keycloak.logout()
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axiosInstance.current = undefined
    }
  }, [initialized, keycloak])

  return axiosInstance
}
