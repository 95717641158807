const SUPERGLUE_TEST = new URLSearchParams(window.location.search).has(
  'superglue'
)

export const featureFlags = {
  CREATE_ACCOUNT: SUPERGLUE_TEST,
  DASHBOARD: SUPERGLUE_TEST,
  DATA_SOURCES: SUPERGLUE_TEST,
  DATA_SOURCES_LANDING_MVP: true,
  DATA_SOURCES_MVP_SINGLE_INGESTION: SUPERGLUE_TEST,
  DATA_SOURCE_EDIT: SUPERGLUE_TEST,
  DATA_ELEMENTS: SUPERGLUE_TEST,
  MODEL: SUPERGLUE_TEST,
  MODELS: SUPERGLUE_TEST,
  PROJECTS: SUPERGLUE_TEST,
  RESOURCES: SUPERGLUE_TEST,
  SCORING: SUPERGLUE_TEST,
  SETTINGS: SUPERGLUE_TEST,
  TRAINING: SUPERGLUE_TEST,
  NOTIFICATIONS: SUPERGLUE_TEST
}
