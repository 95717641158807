import { useKeycloak } from '@react-keycloak/web'
import * as React from 'react'
import { ExtractRouteParams } from 'react-router'
import { Route, Redirect, RouteComponentProps } from 'react-router-dom'
import type { RouteProps } from 'react-router-dom'

import { navigationInfo } from '../config/urls'

interface PrivateRouteParams extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<React.ReactNode>>
    | React.ComponentType<React.ReactNode>
}

const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteParams): JSX.Element => {
  const { keycloak } = useKeycloak()
  const { home } = navigationInfo

  return (
    <Route
      {...rest}
      render={(
        props: RouteComponentProps<ExtractRouteParams<string, string>>
      ) =>
        keycloak?.authenticated
          ? (
          <Component {...props} />
            )
          : (
          <Redirect
            to={{
              pathname: home.getUrl(),
              state: { from: props.location }
            }}
          />
            )
      }
    />
  )
}

export default PrivateRoute
