interface NavigationInfoItem {
    readonly link: string,
    readonly title: string
}

const dashboard : NavigationInfoItem = {
  link: 'Dashboard',
  title: 'Dashboard'
}

const home : NavigationInfoItem = {
  link: 'MLOPS',
  title: 'MLOPS'
}

const dataProcessingAgreement : NavigationInfoItem = {
  link: 'Data Processing Agreement',
  title: 'Data Processing Agreement'
}

const masterLicenceAndServicesAgreement : NavigationInfoItem = {
  link: 'Master Licence and Services Agreement',
  title: 'Master Licence and Services Agreement'
}

const betaTestingTrialLicenceOrder : NavigationInfoItem = {
  link: 'Beta Testing Trial Licence Order',
  title: 'Beta Testing Trial Licence Order'
}

const dataSource : NavigationInfoItem = {
  link: 'Data sources',
  title: 'Data sources'
}

const dataSourcesCreate : NavigationInfoItem = {
  link: 'Create data source',
  title: 'Create data source'
}

const dataElements : NavigationInfoItem = {
  link: 'Data elements',
  title: 'Global data elements'
}

const dataElementsCreate : NavigationInfoItem = {
  link: 'Create Data Element',
  title: 'Create Data Element'
}

const dataExplorer : NavigationInfoItem = {
  link: 'Data sources',
  title: 'Data sources'
}

const dataExplorerConfirm : NavigationInfoItem = {
  link: 'Confirm',
  title: 'Confirm connector selection'
}

const dataExplorerConfigure : NavigationInfoItem = {
  link: 'Configure',
  title: 'Configure connections'
}

const dataExplorerEdit : NavigationInfoItem = {
  link: 'Edit',
  title: 'Edit connection'
}

const dataExplorerSources : NavigationInfoItem = {
  link: 'Sources',
  title: 'Connection sources'
}

const dataExplorerPending : NavigationInfoItem = {
  link: 'Ingesting',
  title: 'Fetching & ingesting data'
}

const login : NavigationInfoItem = {
  link: 'Log in',
  title: 'Login to MLOPS'
}

const models : NavigationInfoItem = {
  link: 'Models',
  title: 'Global Models'
}

const model : NavigationInfoItem = {
  link: 'Model',
  title: 'View Model'
}

const modelCreate : NavigationInfoItem = {
  link: 'Create Model',
  title: 'Create Model'
}

const modelEdit : NavigationInfoItem = {
  link: 'Edit Model',
  title: 'Edit Model'
}

const project : NavigationInfoItem = {
  link: 'Project',
  title: 'View Project'
}

const projectDataSources : NavigationInfoItem = {
  link: 'Project data sources',
  title: 'View Project data sources'
}

const projectDataElements : NavigationInfoItem = {
  link: 'Project data elements',
  title: 'View Project data elements'
}

const projectModels : NavigationInfoItem = {
  link: 'Project models',
  title: 'View Project models'
}

const projectModel : NavigationInfoItem = {
  link: 'Project model',
  title: 'Project model'
}

const projects : NavigationInfoItem = {
  link: 'Projects',
  title: 'View Projects'
}

const resources : NavigationInfoItem = {
  link: 'Resources',
  title: 'Resources'
}

const training : NavigationInfoItem = {
  link: 'Train',
  title: 'Training models'
}

const scoring : NavigationInfoItem = {
  link: 'Score',
  title: 'Scoring models'
}

const settings : NavigationInfoItem = {
  link: 'Settings',
  title: 'Settings'
}

const user : NavigationInfoItem = {
  link: 'User',
  title: 'User'
}

const users : NavigationInfoItem = {
  link: 'Users',
  title: 'Users'
}

export const navigationInfo = {
  betaTestingTrialLicenceOrder,
  dashboard,
  dataSource,
  dataSourcesCreate,
  dataElements,
  dataElementsCreate,
  dataExplorerEdit,
  dataExplorer,
  dataExplorerConfirm,
  dataExplorerConfigure,
  dataExplorerSources,
  dataExplorerPending,
  home,
  dataProcessingAgreement,
  login,
  masterLicenceAndServicesAgreement,
  models,
  model,
  modelCreate,
  modelEdit,
  project,
  projectDataSources,
  projectDataElements,
  projectModels,
  projectModel,
  projects,
  resources,
  scoring,
  settings,
  training,
  user,
  users
}
