import React from 'react'

interface LiProps {
    ariaSelected?: boolean
    ariaDisabled?: boolean
    children: React.ReactNode
    className?: string
    gridCellWidth?: string
    hidden?: boolean
    id?: string
    notificationIndex?: number
    role?: 'tab' | 'option'
}

const Li = ({
  ariaSelected,
  ariaDisabled,
  children,
  className = undefined,
  gridCellWidth,
  hidden = false,
  id,
  notificationIndex,
  role = undefined
} : LiProps) : JSX.Element => {
  const getStyle = () => {
    if (gridCellWidth) {
      return { flex: gridCellWidth }
    }
    if (notificationIndex) {
      return { zIndex: notificationIndex }
    }
    return undefined
  }

  return (
        <li
            aria-disabled={ariaDisabled}
            aria-selected={((role === 'tab' || role === 'option') && ariaSelected) || undefined}
            className={className}
            hidden={hidden || undefined}
            id={id}
            role={role}
            style={getStyle()}
        >
            {children}
        </li>
  )
}

export default Li
