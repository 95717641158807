import {
  DataSource,
  DataSourceActions,
  DataSourceState
} from '../@types/dataSources'

export const DataSourcesInitialState = {
  fetching: false,
  trigger: null,
  items: [],
  error: null,
  addData: null,
  editData: null,
  deleteItem: {
    fetching: false,
    error: null,
    id: null,
    completed: false
  },
  extendData: null,
  airbyteMetadata: null
}

export const DataSourcesReducer = (
  state: DataSourceState,
  action: DataSourceActions
): DataSourceState => {
  switch (action.type) {
    case 'DATA_SOURCES_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      }
    case 'DATA_SOURCES_SUCCESS':
      return {
        ...state,
        fetching: false,
        items: action.items || []
      }
    case 'DATA_SOURCES_ERROR':
      return {
        ...state,
        fetching: false,
        error: action.error || null
      }
    case 'DATA_SOURCE_EXTEND_SET':
      return {
        ...state,
        extendData: {
          id: action.id as string,
          projectId: action.projectId as string
        }
      }
    case 'DATA_SOURCE_EXTEND_REQUEST':
      return {
        ...state,
        extendData: {
          ...state.extendData,
          fetching: true,
          error: null,
          completed: false
        }
      }
    case 'DATA_SOURCE_EXTEND_SUCCESS':
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.id
            ? {
                ...item,
                versions: action.versions,
                source: action.source
              }
            : item
        ),
        extendData: {
          ...state.extendData,
          fetching: false,
          completed: true
        }
      }
    case 'DATA_SOURCE_EXTEND_ERROR':
      return {
        ...state,
        extendData: {
          ...state.extendData,
          fetching: false,
          error: action.error
        }
      }
    case 'DATA_SOURCE_EXTEND_CLEAR':
      return {
        ...state,
        extendData: null
      }
    case 'DATA_SOURCES_ADD_REQUEST':
      return {
        ...state,
        addData: {
          completed: false,
          error: null,
          fetching: true,
          data: action.addData?.data,
          projectId: action.projectId
        }
      }
    case 'DATA_SOURCES_ADD_SUCCESS':
      return {
        ...state,
        items: [...state.items, ...[action.dataSource as DataSource]],
        addData: {
          ...state.addData,
          completed: true,
          fetching: false
        }
      } as DataSourceState
    case 'DATA_SOURCES_ADD_ERROR':
      return {
        ...state,
        addData: {
          ...state.addData,
          fetching: false,
          error: action.error
        }
      }
    case 'DATA_SOURCES_EDIT_SET':
      return {
        ...state,
        editData: {
          error: null,
          projectId: action.projectId as string,
          id: action.id,
          data: action.editData
        }
      }
    case 'DATA_SOURCES_EDIT_REQUEST':
      return {
        ...state,
        editData: {
          ...state.editData,
          fetching: true
        }
      }
    case 'DATA_SOURCES_EDIT_SUCCESS':
      return {
        ...state,
        editData: {
          ...state.editData,
          fetching: false,
          completed: true
        }
      }
    case 'DATA_SOURCES_EDIT_ERROR':
      return {
        ...state,
        editData: {
          ...state.editData,
          error: action.error || null,
          fetching: false
        }
      }
    case 'DATA_SOURCES_EDIT_CLEAR':
      return {
        ...state,
        editData: null
      }
    case 'DATA_SOURCE_DELETE_REQUEST':
      return {
        ...state,
        deleteItem: {
          ...state.deleteItem,
          projectId: action.projectId as string,
          fetching: true,
          completed: false,
          error: null,
          id: action.id as string
        }
      }
    case 'DATA_SOURCE_DELETE_SUCCESS':
      return {
        ...state,
        deleteItem: {
          ...state.deleteItem,
          fetching: false,
          completed: true,
          id: null
        }
      }
    case 'DATA_SOURCE_DELETE_ERROR':
      return {
        ...state,
        deleteItem: {
          ...state.deleteItem,
          fetching: false,
          error: action.error || null
        }
      }
    case 'DATA_SOURCE_DELETE_CLEAR':
      return {
        ...state,
        deleteItem: {
          fetching: false,
          error: null,
          id: null,
          completed: false
        }
      }
    case 'DATA_SOURCES_REQUEST_TRIGGER':
      return {
        ...state,
        trigger: action.trigger
      }
    case 'DATA_SOURCES_REQUEST_TRIGGER_CLEAR':
      return {
        ...state,
        trigger: null
      }
    default:
      return state
  }
}
