import React from 'react'

import ErrorBoundary from '../ErrorBoundary'

const Ol = (
  {
    children,
    className,
    disabled = false,
    id,
    multiSelect,
    onBlur,
    onClick,
    onFocus,
    onKeyDown,
    onKeyPress,
    role,
    tabIndex
  } : {
            children: React.ReactNode,
            className?: string
            disabled?: boolean
            id?: string
            multiSelect?: boolean
            onBlur?: (arg: React.FocusEvent<HTMLOListElement>) => void
            onClick?: (arg: React.MouseEvent<HTMLOListElement>) => void
            onFocus?: (arg: React.FocusEvent<HTMLOListElement>) => void
            onKeyDown? : (arg: React.KeyboardEvent<HTMLOListElement>) => void
            onKeyPress? : (arg: React.KeyboardEvent<HTMLOListElement>) => void
            role?: 'progressbar' | 'listbox'
            tabIndex?: number
        }
) : JSX.Element => (
    <ErrorBoundary>
        <ol
            aria-disabled={disabled || undefined}
            aria-multiselectable={multiSelect}
            className={className || undefined}
            role={role || undefined}
            id={id}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            tabIndex={tabIndex}
        >
            {children}
        </ol>
    </ErrorBoundary>
)

export default Ol
