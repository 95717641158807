import React, { createContext, useReducer } from 'react'

import {
  NotificationsActions,
  NotificationsState
} from '../@types/notifications'
import {
  NotificationsInitialState,
  NotificationsReducer
} from '../reducers/NotificationsReducer'

interface NotificationsStoreProps {
  children: React.ReactNode
}

interface ContextProps {
  stateNotifications: NotificationsState
  dispatchNotifications: React.Dispatch<NotificationsActions>
}

const NotificationsStore = ({
  children
}: NotificationsStoreProps): JSX.Element => {
  const [state, dispatch] = useReducer(
    NotificationsReducer,
    NotificationsInitialState
  )

  /* Commenting out tmp data import as we have not backend data yet */
  // useEffect(() => {
  //     const x = [
  //         {
  //             id: `1`,
  //             header: `default`,
  //             message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
  //             type: `default`
  //         },
  //         {
  //             id: `2`,
  //             header: `error`,
  //             message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
  //             type: `error`
  //         },
  //         {
  //             id: `3`,
  //             header: `warning`,
  //             message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
  //             type: `warning`
  //         },
  //         {
  //             id: `4`,
  //             header: `success`,
  //             message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
  //             type: `success`
  //         },
  //         {
  //             id: `5`,
  //             header: `info`,
  //             message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
  //             type: `info`
  //         }
  //     ]
  //     x.forEach(y => dispatch({type: `NOTIFICATION_ADD`, notification: y as Notification}))
  // }, [])

  return (
    <NotificationsStoreContext.Provider
      value={{ stateNotifications: state, dispatchNotifications: dispatch }}>
      {children}
    </NotificationsStoreContext.Provider>
  )
}

export const NotificationsStoreContext = createContext<ContextProps>(
  {} as ContextProps
)

export default NotificationsStore
