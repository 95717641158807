import React, { createContext, useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'

import {
  DataSource,
  DataSourceActions,
  DataSourceState
} from '../@types/dataSources'
import { ProjectRouteProps } from '../@types/projects'
import { useAxios } from '../hooks/useAxios'
import {
  DataSourcesInitialState,
  DataSourcesReducer
} from '../reducers/DataSourcesReducer'

interface DataSourcesStoreProps {
  children: React.ReactNode
}

interface ContextProps {
  stateDataSource: DataSourceState
  dispatchDataSource: React.Dispatch<DataSourceActions>
}

const DataSourcesStore = ({ children }: DataSourcesStoreProps): JSX.Element => {
  const axiosInstance = useAxios()

  const [stateDataSource, dispatchDataSource] = useReducer(
    DataSourcesReducer,
    DataSourcesInitialState
  )

  const { projectId } = useParams<ProjectRouteProps>()

  useEffect(() => {
    if (stateDataSource.trigger) {
      dispatchDataSource({ type: 'DATA_SOURCES_REQUEST' })
      axiosInstance.current
        ?.get(`/api/v1/projects/${stateDataSource.trigger}/dataSources`)
        .then((res) => {
          dispatchDataSource({
            type: 'DATA_SOURCES_SUCCESS',
            items: res.data as DataSource[]
          })
        })
        .catch((error) => {
          dispatchDataSource({ type: 'DATA_SOURCES_ERROR', error: error })
        })
    }
    return () =>
      dispatchDataSource({ type: 'DATA_SOURCES_REQUEST_TRIGGER_CLEAR' })
  }, [axiosInstance, projectId, stateDataSource.trigger])

  useEffect(() => {
    if (stateDataSource.extendData?.completed) {
      dispatchDataSource({ type: 'DATA_SOURCE_EXTEND_CLEAR' })
    }
  }, [stateDataSource.extendData?.completed])

  useEffect(() => {
    if (
      stateDataSource.extendData &&
      !stateDataSource.extendData.fetching &&
      !stateDataSource.extendData.error &&
      !stateDataSource.extendData.completed
    ) {
      dispatchDataSource({ type: 'DATA_SOURCE_EXTEND_REQUEST' })
      axiosInstance.current
        ?.get(
          `/api/v1/projects/${stateDataSource.extendData.projectId}/dataSources/${stateDataSource.extendData.id}`
        )
        .then((res) => {
          dispatchDataSource({
            type: 'DATA_SOURCE_EXTEND_SUCCESS',
            id: stateDataSource.extendData?.id,
            versions: res.data.versions,
            source: res.data.source
          })
        })
        .catch((error) => {
          dispatchDataSource({ type: 'DATA_SOURCE_EXTEND_ERROR', error })
        })
    }
  }, [axiosInstance, stateDataSource.extendData])

  useEffect(() => {
    if (
      stateDataSource.addData &&
      !stateDataSource.addData.error &&
      !stateDataSource.addData.completed
    ) {
      axiosInstance.current
        ?.post(
          `/api/v1/projects/${stateDataSource.addData.projectId}/dataSources`,
          stateDataSource?.addData?.data
        )
        .then((res) => {
          dispatchDataSource({
            type: 'DATA_SOURCES_ADD_SUCCESS',
            dataSource: res.data as DataSource
          })
          dispatchDataSource({
            type: 'DATA_SOURCES_REQUEST_TRIGGER',
            trigger: stateDataSource.addData?.projectId
          })
        })
        .catch((error) => {
          dispatchDataSource({ type: 'DATA_SOURCES_ADD_ERROR', error })
        })
    }
  }, [axiosInstance, stateDataSource.addData, projectId])

  useEffect(() => {
    if (
      stateDataSource.editData &&
      !stateDataSource.editData.fetching &&
      !stateDataSource.editData.error &&
      !stateDataSource.editData.completed
    ) {
      dispatchDataSource({ type: 'DATA_SOURCES_EDIT_REQUEST' })
      axiosInstance.current
        ?.put(
          `/api/v1/projects/${stateDataSource.editData.projectId}/dataSources/${stateDataSource.editData.id}`,
          stateDataSource.editData.data
        )
        .then(() => dispatchDataSource({ type: 'DATA_SOURCES_EDIT_SUCCESS' }))
        .catch((error) =>
          dispatchDataSource({ type: 'DATA_SOURCES_EDIT_ERROR', error })
        )
    }
  }, [axiosInstance, stateDataSource.editData])

  useEffect(() => {
    if (stateDataSource.deleteItem.id && !stateDataSource.deleteItem.error) {
      axiosInstance.current
        ?.delete(
          `/api/v1/projects/${stateDataSource.deleteItem.projectId}/dataSources/${stateDataSource.deleteItem.id}`
        )
        .then(() => {
          dispatchDataSource({ type: 'DATA_SOURCE_DELETE_SUCCESS' })
          dispatchDataSource({
            type: 'DATA_SOURCES_REQUEST_TRIGGER',
            trigger: stateDataSource.deleteItem?.projectId
          })
        })
        .catch((error) => {
          dispatchDataSource({ type: 'DATA_SOURCE_DELETE_ERROR', error })
        })
    }
  }, [axiosInstance, stateDataSource.deleteItem])

  return (
    <DataSourcesStoreContext.Provider
      value={{ stateDataSource, dispatchDataSource }}>
      {children}
    </DataSourcesStoreContext.Provider>
  )
}

export const DataSourcesStoreContext = createContext<ContextProps>(
  {} as ContextProps
)

export default DataSourcesStore
