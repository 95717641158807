import React from 'react'

interface SmallProps {
    children: React.ReactNode
}

const Small = ({ children } : SmallProps) : JSX.Element => (
    <small>{children}</small>
)

export default Small
