import { ReactKeycloakProvider } from '@react-keycloak/web'
import React from 'react'
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'

import keycloak from '../keycloak'

import App from './App'
import '../styles/index.scss'

const queryClient = new QueryClient()

const AppContainer = (): JSX.Element => (
  <React.StrictMode>
    <ReactKeycloakProvider authClient={keycloak}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ReactKeycloakProvider>
  </React.StrictMode>
)

export default AppContainer
