import React, { useContext, useState } from 'react'

import { NotificationsStoreContext } from '../context/NotificationsStore'

import Ol from './elements/Ol'
import NotificationItem from './NotificationItem'

interface NotificationsProps {
  stacked: boolean
}

const Notifications = ({ stacked = true }: NotificationsProps): JSX.Element => {
  const { stateNotifications } = useContext(NotificationsStoreContext)
  const [isStacked, setIsStacked] = useState(stacked)

  return (
    <div
      aria-live={'polite'}
      className={`notifications${isStacked ? ' notifications-stacked' : ''}`}
      onClick={() => setIsStacked(!isStacked)}>
      <Ol>
        {stateNotifications.notifications.map((notification, index) => (
          <NotificationItem
            key={notification.id}
            id={notification.id}
            type={notification.type}
            header={notification.header}
            message={notification.message}
            index={stateNotifications.notifications.length - index}
          />
        ))}
      </Ol>
    </div>
  )
}

export default Notifications
