import React, { Component, ErrorInfo, ReactNode } from 'react'

import { en } from '../locales/en'

import H2 from './elements/H2'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError (): State {
    return { hasError: true }
  }

  public componentDidCatch (error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo)
  }

  public render (): ReactNode {
    if (this.state.hasError) {
      return <H2>{en.components.errorBoundary.header}</H2>
    }
    return this.props.children
  }
}

export default ErrorBoundary
