export const dataExplorerPage = {
  connectorConfirm: {
    download: 'How to find the API?',
    header: 'The following data sources will be configured',
    warning: `Once the configuration has started you will not be able to add more sources until the process is 
        finished`
  },
  connectorConfiguration: {
    apiHelp: {
      intro: 'Do you need help to find the api information?',
      link: 'Download'
    },
    finishButton: 'Finish & sync data',
    help: {
      button: 'Help me!',
      description: 'If you have had some problems configuring the source we will be happy to help you',
      form: {
        button: 'Send',
        desc: 'Request help in configuring your connectors',
        descriptionLabel: 'Description',
        header: 'How can we help you?',
        problemLabel: 'Problem',
        problemPlaceholder: 'e.g. Fail on mailchimp'
      },
      header: 'Do you need some help?'
    },
    nextButton: 'Configure next data source'
  },
  connectorPending: {
    buttonDataSources: 'Go to Data sources',
    buttonDashboard: 'Go to your dashboard',
    mainMessage: 'Get some coffee and come back later...',
    secondMessage: 'You will receive an email once you data is retrieved and ready to view'
  },
  connectorSelector: {
    label: ' Connection sources',
    allConfigured: `All supported connectors have been configured. If you are looking for more Data sources you can
         influence out decision by selecting "future Data sources" below`
  },
  connectorSuccess: {
    mainMessage: 'Yay - go and look at Data Sources'
  },
  intentsExplorer: {
    link: 'Go to Dashboard',
    title: 'Dashboard connected'
  },
  landing: {
    headerFeatureStore: 'Published in your feature store',
    headerSources: 'Imported data sources',
    headerTransformation: 'Data Transformations',
    newIngestionPaused: 'Once we finish connecting your sources you will be able to connect more'
  },
  navigation: {
    configure: 'Connect data source',
    configures: 'Connect data sources',
    confirm: 'Start configuration',
    previous: 'Previous'
  },
  progressIndicator: {
    stepDashboard: 'Get your dashboard',
    stepSelect: 'Select your Data sources',
    stepConnect: 'Connect your data'
  },
  unsupportedSelector: {
    bespoke: {
      add: 'Add another',
      header: 'Do not find what you are looking for?',
      placeholder: 'e.g. Instagram',
      remove: 'Remove'
    },
    button: 'Notify me',
    fetching: 'Fetching unsupported',
    label: 'Select the ones you would like to see us develop next.',
    moreHeader: 'Looking for more Data sources?',
    modalButton: 'Select future Data sources',
    modalDescription: 'Request other providers to be developed next',
    modalHeader: 'Which ones would you like us to develop next?',
    searchLabel: 'Search data sources',
    searchPlaceholder: 'e.g. Instagram',
    selectorLabel: 'Other Data sources',
    otherLabel: 'Suggest another not in this list',
    otherLabelExtra: 'Suggest another Data Source'
  }
} as const
