export const loginPage = {
  auth: {
    link: 'Go to log in page',
    loadingText: 'Redirecting to login',
    manualWarning: 'Page not redirecting...?',
    sectionHeader: 'Sign in interstitial page'
  },
  create: {
    buttonText: 'Create account',
    header: 'Create account',
    modalDesc: 'Complete the following fields to create an account',
    signInLink: 'Sign in',
    signInPre: 'Already have an account?'
  }
} as const
